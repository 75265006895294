import labelConfig from "./functions/label-config.json";
import { BASE_URL } from "./env";

// let initEvent = new Event("init");
const labelsService = `${BASE_URL}/party/estx-resource-public/v1/labels/`;
// const fetchOptions = {
//   credentials: "include",
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// };

const SUPPORTED_LANGUAGES = ["FR", "NL"];

// LANGUAGE`
export const getLangFromUrl = () =>
  SUPPORTED_LANGUAGES.includes(
    document.location.pathname.split("/")[1].toUpperCase()
  ) && document.location.pathname.split("/")[1].toUpperCase();


const language = () => {
  const langFromUrl = getLangFromUrl();
  localStorage.setItem("language", langFromUrl || "FR");
};

// LABLES
const fetchLabels = async () => {
  try {
    const response = await Promise.all(
      SUPPORTED_LANGUAGES.map((lang) => fetch(`${labelsService}${lang}`))
    );

    return (
      response.every((response) => response.ok === true) &&
      (await Promise.all(response.map((res) => res.json())))
    );
  } catch (e) {
    return "";
  }
};

const filterLabels = (arr) =>
  arr.reduce((a, b) => {
    const i = a.findIndex((x) => x.internalKey === b.internalKey);
    if (i < 0) return [...a, b];

    if (i >= 0) {
      const HBInternalField = arr
        .filter((c) => c.internalKey === b.internalKey)
        .filter((d) => d.internalField === "All");

      const accLabelArray = [...a];
      accLabelArray.splice(i, 1);

      return [...accLabelArray, ...HBInternalField];
    }
  }, []);

const recursiveLabelMapping = (obj, { lang, labels, translations }) => {
  for (const k in obj) {
    if (typeof obj[k] === "object" && obj[k] !== null) {
      recursiveLabelMapping(obj[k], { lang, labels, translations });
    } else {
      const id = obj[k].toString();
      const label = labels[lang];
      const text = label.find((itm) => itm.internalKey === id)?.rawText;
      if (text !== undefined) {
        obj[k] = text;
        translations[lang.toLowerCase()] = {
          ...translations[lang.toLowerCase()],
          ...obj,
        };
      }
    }
  }
};

const mappingTraLabelId = (response) => {
  const LANG = localStorage.getItem("language");
  const res = response;
  const FR = filterLabels(res[0]);
  const NL = filterLabels(res[1]);
  const labels = { FR, NL };

  const translations = { default: { fr: {}, nl: {} } };

  recursiveLabelMapping(
    { ...labelConfig },
    { lang: LANG, labels, translations: translations.default }
  );

  return translations;
};

export const getLabels = async () => {
  const translations = await mappingTraLabelId(await fetchLabels());
  localStorage.setItem("translations", JSON.stringify(translations));
  return translations;
};


const initDOD = async () => {
  const init = async () => {
    language();
    await getLabels();
  };

  await init();
};

export default initDOD;
