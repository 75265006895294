import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {HeaderProps} from 'components/HeaderProps';
import {translate} from "helpers/i18n";
import {register, Scenario} from "helpers/analytics/analytics";

export const DeceasedIntroPage = () => {
    const history = useHistory();

    useEffect(() => {
        register(Scenario.DECEASED_START_QUESTIONS_PAGE);
    }, []);

    return (
        <MfContent
            header={<HeaderProps pathName="/deceasedintro"/>}
            actions={[
                <MfButton
                    key="next"
                    onClick={() => history.push({pathname: "/deceasedname"})}
                    id="deceased-intro-next"
                >
                    {translate('deceased_intro.next_button')}
                </MfButton>,
            ]}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo as="h3" variant="title3" weight="bold">
                    {translate('deceased_intro.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="2"/>
            <MfRow>
                <MfTypo variant="bodytext1">
                    {translate('deceased_intro.description')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4"/>
        </MfContent>
    );
}

