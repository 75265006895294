import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {MfSingleSelect, MfTextOption} from '@bnppf-ui-library/mfselect';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {useAppDataContext} from 'contexts/app-data'
import {ACTIONS} from "helpers/constants";
import {translate} from "helpers/i18n";
import {register, Scenario} from "helpers/analytics/analytics";


export const RelationshipForm = props => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const [relationshipToDecease, setRelationshipToDecease] = useState(state.declarant.relationshipToDecease)
    const handleRelationChange = (value) => {
        setRelationshipToDecease(value)
        dispatch({
            type: ACTIONS.SET_DECLARANT_RELATIONSHIP,
            relationshipToDecease: value,
        });
        history.push({pathname: "/inheritanceContact"})
    }

    useEffect(() => {
        register(Scenario.DECLARANT_RELATION_DECEASED_PAGE);
    }, []);

    return (
        <MfContent {...props} header={<HeaderProps pathName="/declarantRelation"/>}>
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('declarant_info_relationship.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow style={{marginBottom: '40px'}}>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={relationshipToDecease}
                    onChange={handleRelationChange}>
                    <MfTextOption value="SPOUSE" label={translate('relationship.option_text_spouse')}/>
                    <MfTextOption value="LEGALLY_COHABITING"
                                  label={translate('relationship.option_text_legal_partner')}/>
                    <MfTextOption value="CHILD" label={translate('relationship.option_text_child')}/>
                    <MfTextOption value="PARENT" label={translate('relationship.option_text_parent')}/>
                    <MfTextOption value="SIBLING" label={translate('relationship.option_text_sibling')}/>
                    <MfTextOption value="OTHER" label={translate('relationship.option_text_other')}/>
                </MfSingleSelect>
            </MfRow>
        </MfContent>
    );
};

