import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import {useHistory} from "react-router-dom";
import {MfInputField} from '@bnppf-ui-library/mfinputfield';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {useAppDataContext} from 'contexts/app-data'
import {CountryContext} from 'contexts/country-context'
import {MfComplexPicker} from '@bnppf-ui-library/mfcomplex-picker';
import {MfCell} from '@bnppf-ui-library/mfcell';
import {validateHouseNumberOrBox, validateStreetName} from "helpers/validations/TextValidations";
import {CityContext} from "contexts/city-context";
import {ACTIONS} from "helpers/constants";
import {translate} from "helpers/i18n";
import {register, Scenario} from "helpers/analytics/analytics";


const InputWrapperFlex1 = styled.div`
    flex: 1 1 0;
`;

const InputWrapperFlex2 = styled.div`
    flex: 2 1 0;
`;

const Separator = styled.div`
    border: none;
    border-top: 1px solid #dfe1ea;
`;

const InputWrapper = styled.div`
    margin-right: 1rem;
    flex: 1 1 0;
`;

const FlexRowWrapper = styled.div`
    display: flex;
`;

export const AddressFrom = props => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const {postalAddress} = state.declarant;
    const countryValue = useContext(CountryContext);
    const postalCodeAndCityState = useContext(CityContext);
    const [street, setStreet] = useState(postalAddress.street);
    const [number, setNumber] = useState(postalAddress.number);
    const [box, setBox] = useState(postalAddress.box);
    const [streetError, setStreetError] = useState('')
    const [numberError, setNumberError] = useState('')
    const [boxError, setBoxError] = useState('')
    const [postalCode, setPostalCode] = useState(postalAddress.postalCode);
    const [postalCodeError, setPostalCodeError] = useState('');
    const [municipality, setMunicipality] = useState(postalAddress.municipality);
    const [municipalityError, setMunicipalityError] = useState('')
    const [country, setCountry] = useState(postalAddress.country);
    const [showCountryPicker, setShowCountryPicker] = useState(false);
    const [showPostalCodeAndCity, setShowPostalCodeAndCity] = useState(false);

    const handleCountryPickerChange = useCallback(
        value => {
            setCountry(value);
            setPostalCode('');
            setPostalCodeError('');
            setMunicipality('');
            setMunicipalityError('');
            setShowCountryPicker(false);
        },
        [setCountry, setShowCountryPicker]
    );

    const handlePostalCodeAndCityPickerChange = useCallback(
        ({name, zipCode}) => {
            setPostalCode(zipCode);
            setMunicipality(name);
            setShowPostalCodeAndCity(false);
        },
        [setShowPostalCodeAndCity]
    );

    const handleCountrySearch = list => searchTerm =>
        list.filter(({name}) => name.toLowerCase().includes(searchTerm?.toLowerCase()));

    const handlePostalCodeAndCitySearch = list => searchTerm =>
        list.filter(({
                         name,
                         zipCode
                     }) => name.toLowerCase().includes(searchTerm?.toLowerCase()) || zipCode.toLowerCase().includes(searchTerm?.toLowerCase()));


    const handleStreetChange = (value) => {
        setStreetError(validateStreetName(value));
        setStreet(value);
    }

    const handleNumberChange = (value) => {
        setNumberError(validateHouseNumberOrBox(value, false));
        setNumber(value);
    }

    const handleBoxChange = (value) => {
        setBoxError(validateHouseNumberOrBox(value, false));
        setBox(value);
    }

    const onPostalCodeChange = (value) => {
        setPostalCodeError(validateHouseNumberOrBox(value))
        setPostalCode(value);
    };

    const onMunicipalityChange = (value) => {
        setMunicipalityError(validateStreetName(value))
        setMunicipality(value);
    };

    const storeAndGoToNext = () => {
        dispatch({
            type: ACTIONS.SET_DECLARANT_POSTAL_ADDRESS,
            postalAddress: {
                street,
                number,
                box,
                postalCode,
                municipality,
                country
            }
        });

        history.push({pathname: "/deceasedintro"});
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_POSTAL_ADDRESS_PAGE);
    }, []);

    return (
        <MfContent
            {...props}
            header={<HeaderProps pathName="/address"/>}
            actions={[
                <MfButton
                    key="next"
                    onClick={storeAndGoToNext}
                    disabled={streetError !== '' || street === '' || postalCode === '' || municipality === ''}
                >
                    {translate('contact_mode_address.button')}
                </MfButton>,
            ]}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('contact_mode_address.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="5"/>
            <MfRow>
                <MfInputField
                    name="STREET"
                    label={translate('contact_mode_address.field_street')}
                    error={streetError}
                    maxLength={35}
                    value={street}
                    onChange={(e) => handleStreetChange(e.target.value)}/>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <FlexRowWrapper>
                    <InputWrapper>
                        <MfInputField
                            name="NUMBER"
                            label={translate('contact_mode_address.field_number')}
                            error={numberError}
                            maxLength={5}
                            value={number}
                            onChange={(e) => handleNumberChange(e.target.value)}
                            style={{flex: '1'}}/>
                    </InputWrapper>
                    <InputWrapperFlex1>
                        <MfInputField
                            name="BOX"
                            label={translate('contact_mode_address.field_box')}
                            error={boxError}
                            maxLength={5}
                            value={box}
                            onChange={(e) => handleBoxChange(e.target.value)}
                            style={{flex: '1'}}/>
                    </InputWrapperFlex1>
                </FlexRowWrapper>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <FlexRowWrapper>
                    <MfComplexPicker
                        title={translate('contact_mode_address.postal_municipality_popup_title')}
                        list={postalCodeAndCityState?.fullCityList}
                        recommendations={[]}
                        show={showPostalCodeAndCity}
                        hasSearch
                        search={handlePostalCodeAndCitySearch}
                        searchLabel={translate('contact_mode_address.search')}
                        debounce={100}
                        RenderItem={({name, zipCode, onClick}) =>
                            name ? (
                                <>
                                    <MfCell value={`${zipCode} - ${name}`} onClick={onClick}
                                            style={{cursor: 'pointer', height: '48px'}}/>
                                    <Separator/>
                                </>
                            ) : (
                                <div style={{height: '48px', width: '100%'}}/>
                            )
                        }
                        onClose={() => setShowPostalCodeAndCity(false)}
                        onChange={handlePostalCodeAndCityPickerChange}
                    />
                    <InputWrapper>
                        <MfInputField
                            name="POSTAL"
                            label={translate('contact_mode_address.field_postal')}
                            required
                            error={postalCodeError}
                            maxLength={10}
                            value={postalCode}
                            onFocus={() => {
                                if (country.code !== 'BE') {
                                    return
                                }
                                setShowPostalCodeAndCity(true);
                            }}
                            onChange={(e) => onPostalCodeChange(e.target.value)}
                            style={{flex: '1'}}/>
                    </InputWrapper>
                    <InputWrapperFlex2>
                        <MfInputField
                            name="MUNICIPALITY"
                            label={translate('contact_mode_address.field_municipality')}
                            required
                            maxLength={35}
                            error={municipalityError}
                            value={municipality}
                            onFocus={() => {
                                if (country.code !== 'BE') {
                                    return
                                }
                                setShowPostalCodeAndCity(true);
                            }}
                            onChange={(e) => onMunicipalityChange(e.target.value)}
                            style={{flex: '1'}}/>
                    </InputWrapperFlex2>

                </FlexRowWrapper>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <MfInputField
                    name="COUNTRY"
                    label=""
                    value={country.name}
                    placeholder="Choose item"
                    onFocus={() => setShowCountryPicker(true)}
                    onChange={() => true}/>
            </MfRow>
            <MfComplexPicker
                title={translate('contact_mode_address.country_popup_title')}
                list={countryValue.fullCountryList}
                recommendations={[]}
                show={showCountryPicker}
                hasSearch
                search={handleCountrySearch}
                searchLabel={translate('contact_mode_address.search')}
                debounce={100}
                RenderItem={({name, onClick}) =>
                    name ? (
                        <>
                            <MfCell value={name} onClick={onClick} style={{cursor: 'pointer', height: '48px'}}/>
                            <Separator/>
                        </>
                    ) : (
                        <div style={{height: '48px', width: '100%'}}/>
                    )
                }
                onClose={() => setShowCountryPicker(false)}
                onChange={handleCountryPickerChange}
            />
        </MfContent>
    );
}

