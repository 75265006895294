import React, {useEffect, useMemo, useState} from 'react';
import { useHistory } from "react-router-dom";
import { MfTypo } from '@bnppf-ui-library/mftypo';
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { MfButton } from "@bnppf-ui-library/mfbutton";
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfUpload } from '@bnppf-ui-library/mfupload';
import { HeaderProps } from 'components/HeaderProps';
import { UploadDocument } from 'services/UploadDocument';
import { ACTIONS } from "helpers/constants";
import { translate } from "helpers/i18n";
import {useAppDataContext} from 'contexts/app-data'
import {register, Scenario} from "helpers/analytics/analytics";

const acceptedExtensions = ["application/pdf", "image/jpeg", "image/png"];

export const UploadPage = ({ ...props }) => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const [files, setFiles] = useState(state.filesList)
    const [inputError, setInputError] = useState('');

    const canMoveToNextPage = useMemo(() => {
        if (files.length === 0) { 
            return false; 
        }
       const isFileUploadInProgress =  files.find((file) => file.loading === true);
       return typeof isFileUploadInProgress === "undefined";
    }, [files])

    const updateFileStatus = (exisitngFiles, fileToBeUpdated) => {
        if (exisitngFiles.length === 0) {
            return [fileToBeUpdated];
        }
        if (fileToBeUpdated.id === "") {
            return exisitngFiles.concat([fileToBeUpdated]);
        }
        return exisitngFiles.map((existingFile) => {
            if (existingFile.name === fileToBeUpdated.name) {
                return { ...existingFile, ...fileToBeUpdated }
            }
            return { ...existingFile };
        });

    }
    const handleFileChange = (fileListOnChange) => {
        setInputError('');
        if (fileListOnChange.length > 3) {
            setInputError(translate('upload_certificate.upload_error_maxdocument'));
            return;
        }

        const isFilesDeleted = files.length > fileListOnChange.length;
        if (isFilesDeleted) {
            setFiles(fileListOnChange);
            return;
        }

        const existingFileNames = files.map(function (file) {
            return file.name;
        });
        fileListOnChange = fileListOnChange.filter(function (file) {
            return existingFileNames.indexOf(file.name) == -1;
        });

        fileListOnChange.forEach((filetoBeUploaded) => {
            if (filetoBeUploaded.id) {
                return;
            }
            UploadDocument(
                { file: filetoBeUploaded },
                (id, file) => {
                    if (typeof file.error !== 'undefined' && file.error !== '') {
                        setInputError(file.error);
                        setFiles((currentFilesInstate) => currentFilesInstate.filter(obj => obj.name !== file.name))
                        return;
                    }
                    file.id = id || '';
                    setFiles((currentFilesInstate) => updateFileStatus(currentFilesInstate, file));
                }, state.uniqueID
            );
        })
    }

    const moveToNextPage = () => {
        dispatch({
            type: ACTIONS.SET_FILE_LIST,
            filesList: files
        });
        history.push({ pathname: "/declarantName" });
    };

    useEffect(() => {
        register(Scenario.UPLOAD_PAGE);
    }, []);

    return (
        <div id="UploadPage">
            <MfContent
                header={<HeaderProps pathName="/upload" />}

                actions={[
                    <MfButton key="next" onClick={moveToNextPage} disabled={!canMoveToNextPage}>
                        {translate('upload_certificate.next_button')}
                    </MfButton>,
                ]}
            >
                <MfSpacer size="5" />
                <MfRow>
                    <MfTypo variant="title3" weight="bold">
                        {translate('upload_certificate.title')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="4" />
                <MfRow>
                    <MfUpload {...props}
                        files={files && !files["error"] ? files : []}
                        onChange={(fs) => handleFileChange(fs)}
                        label={translate('upload_certificate.upload_filed')}
                        subLabel={translate('upload_certificate.upload_placeholder')}
                        accept={acceptedExtensions.join(',')}
                        fileSizeText={translate('upload_certificate.upload_placeholder')}
                        error={inputError}
                    />
                </MfRow>
            </MfContent>
        </div>
    )
}

export default UploadPage
