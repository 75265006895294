import React, {FC, useCallback, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import {createPhoneFormatter} from "./format"
import {MfInputField, useInput} from '@bnppf-ui-library/mfinputfield';
import {MfComplexPicker, RenderItem} from "@bnppf-ui-library/mfcomplex-picker"
import {MfSpinner} from '@bnppf-ui-library/mfspinner';

const ContainerStyles = styled.div`
    display: flex;
    gap: ${({theme}) => theme.spacing(1)};
`

const CountryContainerStyles = styled.div`
    width: 80px;
`

const NationalContainerStyles = styled.div`
    flex: 1;
`

const recommendations = []

function createCountrySearch(list) {
    return function searchTermInList(term) {
        // Make the searchterm lowercase to ignore case
        term = term.toLowerCase()

        return list.filter(
            (item) =>
                item.name.toLowerCase().indexOf(term) === 0 ||
                item.code.toLowerCase().indexOf(term) === 0 ||
                item.number.toLowerCase().indexOf(term) === 0
        )
    }
}

const MfPhoneComponentPropTypes = {
    countrySelectionTitle: PropTypes.string,
    listTitle: PropTypes.string,
    recommendationsTitle: PropTypes.string,
    searchLabel: PropTypes.string,
    emptySearchLabel: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    countryList: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            number: PropTypes.string.isRequired,
            flagImageUrl: PropTypes.string,
            name: PropTypes.string,
        })
    ).isRequired,
    prefix: PropTypes.string,
    countryLabel: PropTypes.string,
    numberLabel: PropTypes.string
}


const defaultProps = {
    prefix: "+",
    countrySelectionTitle: "Country Selection",
    listTitle: "All Countries",
    recommendationsTitle: "Most Selected",
    searchLabel: "Search",
    emptySearchLabel: "No countries Found",
};
type MfPhoneComponentProps = PropTypes.InferProps<typeof MfPhoneComponentPropTypes>;

export const MfPhoneComponent: FC<MfPhoneComponentProps> = ({
                                                                value,
                                                                onChange,
                                                                prefix,
                                                                countryList,
                                                                recommendationsTitle,
                                                                listTitle,
                                                                countrySelectionTitle,
                                                                searchLabel,
                                                                emptySearchLabel,
                                                                countryLabel,
                                                                numberLabel,
                                                                ...props
                                                            }: MfPhoneComponentProps & typeof defaultProps) => {
    const [countryPicker, setCountryPicker] = useState(false)

    // find the country based on the value (full phone number)
    const country = useMemo(() => {
        if (value && value[prefix.length] === "1") {
            // If the first number of the country code equals 1 we are dealing with a special group of possible
            // countries outside the USA that are possible. Check these first before determining it is USA

            // The 3 digits after the one are either part of the country code, or determines the territory if in USA
            const subCountryCode = value.slice(prefix.length, prefix.length + 4)

            return (
                countryList.find((currentCountry) => currentCountry.number === subCountryCode) || {
                    number: "1",
                    code: "US",
                }
            )
        }

        return (
            countryList.find(
                (currentCountry) => value.indexOf(`${prefix}${currentCountry.number}`) === 0
            ) || {
                number: "32",
                code: "BE",
            }
        )
    }, [countryList, value, prefix])

    // Use the useInput hook to handle the errors and formatting of the national number part
    const {inputProps, ...otherProps} = useInput("nationalNumber", {
        initial: value.slice(`${prefix}${country.number}`.length),
        formatter: createPhoneFormatter(country.number),
        mask: (x) => {
            return x === "" || !/[^\d]/g.test(x)
        },
    })

    const nationalNumberInputProps = inputProps()

    // Function to update the value when the national number is changed
    useEffect(() => {
        const unformattedNumber = createPhoneFormatter(country.number).unformat(
            nationalNumberInputProps.value
        )
        const newValue = `${prefix}${country.number}${unformattedNumber}`

        if (newValue !== value) {
            onChange(newValue, {prefix: `${prefix}${country.number}`, value: unformattedNumber})
        }
    }, [nationalNumberInputProps.value])

    // Function to update the value when the country is changed
    const setCountry = useCallback(
        (newCountry) => {
            const newValue = `${prefix}${newCountry.number}${nationalNumberInputProps.value}`

            if (newValue !== value) {
                onChange(newValue, {prefix: `${prefix}${newCountry.number}`, value: nationalNumberInputProps.value})
            }
        },
        [value, nationalNumberInputProps.value, prefix]
    )

    const closeCountrySelection = useCallback(() => {
        setCountryPicker(false)
    }, [])

    return (
        <ContainerStyles {...props}>
            <MfComplexPicker
                title={countrySelectionTitle}
                listTitle={listTitle}
                hasSearch
                recommendationsTitle={recommendationsTitle}
                searchLabel={searchLabel}
                emptyText={emptySearchLabel}
                search={createCountrySearch}
                list={countryList}
                recommendations={recommendations}
                RenderItem={(props) => (
                    // eslint-disable-next-line react/prop-types
                    <RenderItem {...props} onClick={props.onClick}>{`${props.name} (+${props.number})`}</RenderItem>
                )}
                show={countryPicker}
                onClose={closeCountrySelection}
                onChange={(x) => {
                    setCountry(x)
                    closeCountrySelection()
                }}
            />
            <CountryContainerStyles>
                <MfInputField
                    label={countryLabel}
                    value={`+${country.number}`}
                    onChange={() => true}
                    onFocus={() => setCountryPicker(true)}
                    name="countrycode"
                    aria-label="country code"
                />
            </CountryContainerStyles>
            <NationalContainerStyles>
                <MfInputField
                    {...nationalNumberInputProps}
                    {...otherProps}
                    type="tel"
                    label={numberLabel}
                    aria-label="phone number"
                    onBlur={(event) => {
                        let value = event.target.value
                        const blurFormatter = createPhoneFormatter(country.number)

                        if (value.indexOf("0") === 0) {
                            value = value.slice(1)
                        }

                        nationalNumberInputProps.onChange({
                            ...event,
                            target: {
                                ...event.target,
                                value: blurFormatter.format(value),
                            },
                        })
                    }}
                />
            </NationalContainerStyles>
        </ContainerStyles>
    )
};

MfPhoneComponent.defaultProps = defaultProps

export const MfPhoneCustom = ({countryList, ...props}) => {
    if (!countryList || !Array.isArray(countryList) || countryList.length === 0)
        return <MfSpinner small/>

    return <MfPhoneComponent countryList={countryList} {...props} />
}

MfPhoneCustom.propTypes = {
    countryList: PropTypes.array.isRequired,
}
