import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {MfInputField} from '@bnppf-ui-library/mfinputfield';
import {HeaderProps} from 'components/HeaderProps';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {useAppDataContext} from 'contexts/app-data'
import {ACTIONS} from "helpers/constants";
import {validateName} from "helpers/validations/TextValidations";
import {translate} from "helpers/i18n";
import {register, Scenario} from "helpers/analytics/analytics";

export const NameForm = props => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const [firstname, setFirstname] = useState(state.declarant.firstname)
    const [lastname, setLastname] = useState(state.declarant.lastname)
    const [firstnameError, setFirstNameError] = useState('')
    const [lastnameError, setLastnameError] = useState('')

    const handleFirstnameChange = (value) => {
        setFirstNameError(validateName(value));
        setFirstname(value);
    }

    const handleLastnameChange = (value) => {
        setLastnameError(validateName(value));
        setLastname(value);
    }

    const moveToNext = () => {
        dispatch({
            type: ACTIONS.SET_DECLARANT_FIRST_NAME,
            firstname
        });
        dispatch({
            type: ACTIONS.SET_DECLARANT_LAST_NAME,
            lastname
        });
        history.push({pathname: "/declarantEmail"})
    }

    useEffect(() => {
        register(Scenario.DECLARANT_NAME_PAGE);
    }, []);

    return (
        <MfContent
            {...props}
            header={<HeaderProps pathName="/declarantName"/>}
            actions={[
                <MfButton
                    key="next"
                    onClick={moveToNext}
                    disabled={firstnameError !== '' || lastnameError !== '' || firstname === '' || lastname === ''}
                >
                    {translate('declarant_info_name.button')}
                </MfButton>
            ]}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('declarant_info_name.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="5"/>
            <MfRow>
                <MfInputField
                    name="FIRSTNAME"
                    label={translate('declarant_info_name.first_name')}
                    required
                    onChange={(e) => handleFirstnameChange(e.target.value)}
                    value={firstname}
                    maxLength={20}
                    error={firstnameError}
                />
            </MfRow>
            <MfSpacer size="3"/>
            <MfRow>
                <MfInputField
                    name="NAME"
                    label={translate('declarant_info_name.last_name')}
                    required
                    onChange={(e) => handleLastnameChange(e.target.value)}
                    value={lastname} maxLength={40}
                    error={lastnameError}
                />
            </MfRow>
        </MfContent>
    );
}
