import { ACTIONS } from "helpers/constants";

export enum CAUSE_OF_DEATH {
    NATURAL = "NATURAL",
    ACCIDENT = "ACCIDENT",
    OTHER = "OTHER"
}

export enum CIVIL_STATUS {
    MARRIED = "MARRIED",
    DIVORCEE = "DIVORCEE",
    WIDOW = "'WIDOWER, WIDOW'",
    SINGLE = "SINGLE",
    OTHER = "OTHER",
    LEGALLY_COHABITING = "LEGALLY_COHABITING"
}

export enum RELATIONSHIP {
    SPOUSE = "SPOUSE",
    LEGALLY_COHABITING = "LEGALLY_COHABITING",
    CHILD = "CHILD",
    PARENT = "PARENT",
    SIBLING = "SIBLING",
    OTHER = "OTHER"
}

export enum CONTACT_MODE {
    EMAIL = "EMAIL",
    PHONE = "PHONE"
}

const generateUUID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

const initialState = {
    uniqueID: generateUUID(),
    filesList: [],
    declarant: {
        firstname: '',
        lastname: '',
        emailAddress: '',
        relationshipToDecease: 0,
        contactMethod: [],
        isDeclarantSPOC: '',
        deliveryMode: '',
        postalAddress: {
            street: "",
            number: "",
            box: "",
            postalCode: "",
            municipality: "",
            country: {
                name: 'Belgium',
                code: 'BE'
            }
        }
    },
    contact: {
        firstname: '',
        lastname: '',
        emailAddress: '',
        relationshipToDecease: null
    },
    contactNumber: {
        fixedPhoneNumber: '+32',
        gsmPhoneNumber: '+32',
        phoneFixed: {
            selected: "fixed", // to-do need to check its fixed or gsm
            prefix: "",
            value: ""
        },
        phoneGsm: {
            selected: "gsm",
            prefix: "",
            value: ""
        }
    },
    deceased: {
        firstname: "",
        lastname: "",
        birthdate: '',
        dateOfDeath: '',
        causeOfDeath: null,
        accountNumber: '',
        civilStatus: null
    }
};

const reducers = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_FILE_LIST:
            return { ...state, filesList: action.filesList };
        case ACTIONS.SET_DECLARANT_FIRST_NAME:
            return { ...state, declarant: { ...state.declarant, firstname: action.firstname } };
        case ACTIONS.SET_DECLARANT_LAST_NAME:
            return { ...state, declarant: { ...state.declarant, lastname: action.lastname } };
        case ACTIONS.SET_DECLARANT_EMAIL:
            return { ...state, declarant: { ...state.declarant, emailAddress: action.emailAddress } };
        case ACTIONS.SET_DECLARANT_RELATIONSHIP:
            return { ...state, declarant: { ...state.declarant, relationshipToDecease: action.relationshipToDecease } };
        case ACTIONS.SET_DECLARANT_ISDECLARANTSPOC:
            return { ...state, declarant: { ...state.declarant, isDeclarantSPOC: action.isDeclarantSPOC } };
        case ACTIONS.SET_DECLARANT_POSTAL_ADDRESS:
            return { ...state, declarant: { ...state.declarant, postalAddress: action.postalAddress } };
        case ACTIONS.SET_CONTACT_FIRST_NAME:
            return { ...state, contact: { ...state.contact, firstname: action.firstname } };
        case ACTIONS.SET_CONTACT_LAST_NAME:
            return { ...state, contact: { ...state.contact, lastname: action.lastname } };
        case ACTIONS.SET_CONTACT_EMAIL:
            return { ...state, contact: { ...state.contact, emailAddress: action.emailAddress } };
        case ACTIONS.SET_CONTACT_RELATIONSHIP:
            return { ...state, contact: { ...state.contact, relationshipToDecease: action.relationshipToDecease } };
        case ACTIONS.SET_DELIVERY_MODE:
            return { ...state, declarant: { ...state.declarant, deliveryMode: action.deliveryMode } };
        case ACTIONS.SET_CONTACT_METHOD:
            return { ...state, declarant: { ...state.declarant, contactMethod: action.contactMethod } };
        case ACTIONS.SET_CONTACT_PHONE_FIXED:
            return {
                ...state,
                contactNumber: {
                    ...state.contactNumber,
                    phoneFixed: action.phoneFixed.fixedPhoneNumberAsKeyValue,
                    fixedPhoneNumber: action.phoneFixed.fixedPhoneNumber
                }
            };
        case ACTIONS.SET_CONTACT_PHONE_GSM:
            return {
                ...state,
                contactNumber: {
                    ...state.contactNumber,
                    phoneGsm: action.phoneGsm.gsmPhoneNumberAsKeyValue,
                    gsmPhoneNumber: action.phoneGsm.gsmPhoneNumber
                }
            };
        case ACTIONS.SET_DECEASED_FIRST_NAME:
            return { ...state, deceased: { ...state.deceased, firstname: action.firstname } };
        case ACTIONS.SET_DECEASED_LAST_NAME:
            return { ...state, deceased: { ...state.deceased, lastname: action.lastname } };
        case ACTIONS.SET_DECEASED_BIRTHDATE:
            return { ...state, deceased: { ...state.deceased, birthdate: action.birthdate } }
        case ACTIONS.SET_DECEASED_DATE_OF_DEATH:
            return { ...state, deceased: { ...state.deceased, dateOfDeath: action.dateOfDeath } }
        case ACTIONS.SET_DECEASED_ACCOUNT_NUMBER:
            return { ...state, deceased: { ...state.deceased, accountNumber: action.accountNumber } }
        case ACTIONS.SET_CAUSE_DEATH:
            return {
                ...state,
                deceased: {
                    ...state.deceased,
                    causeOfDeath: action.causeOfDeath
                }
            }
        case ACTIONS.SET_CIVIL_STATUS:
            return {
                ...state,
                deceased: {
                    ...state.deceased,
                    civilStatus: action.civilStatus
                }
            }
        default:
            return state;
    }
};

export { initialState, reducers };
