import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {MfSingleSelect, MfTextOption} from '@bnppf-ui-library/mfselect';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {useAppDataContext} from 'contexts/app-data'
import {ACTIONS} from "helpers/constants";
import {translate} from "helpers/i18n";
import {RELATIONSHIP} from "reducers/deathDeclarationReducer";
import {register, Scenario} from "helpers/analytics/analytics";


export const ContactRelationshipForm = () => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const handleRelationChange = (value: RELATIONSHIP) => {
        dispatch({
            type: ACTIONS.SET_CONTACT_RELATIONSHIP,
            relationshipToDecease: value,
        });
        history.push({pathname: "/phoneoremail"})
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_RELATION_DECEASE_PAGE);
    }, []);

    return (
        <MfContent
            header={<HeaderProps pathName="/contactRelation"/>}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('contact_info_relationship.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={state.contact.relationshipToDecease}
                    onChange={handleRelationChange}>
                    <MfTextOption
                        value={RELATIONSHIP.SPOUSE}
                        label={translate('relationship.option_text_spouse')}
                    />
                    <MfTextOption
                        value={RELATIONSHIP.LEGALLY_COHABITING}
                        label={translate('relationship.option_text_legal_partner')}
                    />
                    <MfTextOption
                        value={RELATIONSHIP.CHILD}
                        label={translate('relationship.option_text_child')}
                    />
                    <MfTextOption
                        value={RELATIONSHIP.PARENT}
                        label={translate('relationship.option_text_parent')}
                    />
                    <MfTextOption
                        value={RELATIONSHIP.SIBLING}
                        label={translate('relationship.option_text_sibling')}
                    />
                    <MfTextOption
                        value={RELATIONSHIP.OTHER}
                        label={translate('relationship.option_text_other')}
                    />
                </MfSingleSelect>
            </MfRow>
            <MfSpacer size="6"/>
        </MfContent>
    );
};

