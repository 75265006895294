import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {BASE_URL} from '../env'
import {getLanguage} from "helpers/getLanguage";

const defaultCity = "BE"
const cityService = `${BASE_URL}/party/estx-resource-public/v1/cities/`

async function fetchCity() {
    const cities = await (await fetch(`${cityService}${(getLanguage())}` + `?iso2=` + defaultCity)).json()

    return ({
        fullCityList: cities.map(city => ({
            name: city.description,
            code: city.iso2,
            zipCode: city.zipCode
        }))
    })
}

const initialState = {
    fullCityList: []
};

export const CityContext = React.createContext(initialState);

export function CityContextProvider(props) {
    const [cityList, setCityList] = useState(initialState)

    useEffect(() => {
        fetchCity().then(res => setCityList(res))
    }, [])

    return (
        <CityContext.Provider value={cityList}>
            {props.children}
        </CityContext.Provider>
    )
}

CityContextProvider.propTypes = {
    children: PropTypes.node
};
