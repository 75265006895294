import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useHistory} from "react-router-dom";

import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfDatePicker} from '@bnppf-ui-library/mfdatepicker';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';

import {translate} from "helpers/i18n";
import {HeaderProps} from 'components/HeaderProps';
import {ACTIONS} from "helpers/constants";
import {useAppDataContext} from "contexts/app-data";
import {getLanguage} from "helpers/getLanguage";
import {register, Scenario} from "helpers/analytics/analytics";

const Content = styled.div`
    overflow: auto!important;
`;

const todayTemp = new Date()
const minDateTemp = new Date('1900-01-01')
const todayString = todayTemp.toISOString().split("T")[0]
const minDateString = minDateTemp.toISOString().split("T")[0]
const today = new Date(todayString)
const minDate = new Date(minDateString);

export const DOBForm = () => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const [birthdate, setBirthdate] = useState(state.deceased.birthdate);
    const [isDateValid, setIsDateValid] = useState(false);

    const validation = (value) => {
        setIsDateValid(false);

        if (!value || !value.length) {
            return translate('deceased_birthdate.mandatory_date');
        }

        const date = new Date(value)
        const isValidFormat = date instanceof Date && !isNaN(date.getTime())

        if (!isValidFormat || (date > today) || (minDate > date)) {
            return translate('deceased_birthdate.invalid_date');
        }

        setIsDateValid(true);
    }

    const handleBirthdateChange = (value) => {
        setBirthdate(value)
    }

    const storeAndGoToNext = () => {
        dispatch({
            type: ACTIONS.SET_DECEASED_BIRTHDATE,
            birthdate
        });

        history.push({pathname: "/dod"});
    }

    useEffect(() => {
        register(Scenario.DECEASED_BIRTHDATE_PAGE);
    }, []);

    return (
        <div id="DeceasedBirthdate">
            <Content as={MfContent}
                header={<HeaderProps pathName="/dob"/>}
                actions={[
                    <MfButton
                        key="next"
                        onClick={storeAndGoToNext}
                        disabled={birthdate === '' || !isDateValid}
                    >
                        {translate('deceased_birthdate.next_button')}
                    </MfButton>,
                ]}
            >
                <MfSpacer size="5"/>
                <MfRow>
                    <MfTypo variant="title3" weight="bold">
                        {translate('deceased_birthdate.title')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="4"/>
                <MfRow>
                    <MfDatePicker
                        label={translate('deceased_birthdate.birthdate_label')}
                        max={todayString}
                        min={minDateString}
                        name="birthdate"
                        value={birthdate}
                        onChange={handleBirthdateChange}
                        validation={validation}
                        errorOnTouched={true}
                        locale={`${getLanguage()}-BE`}
                    />
                </MfRow>
            </Content>
        </div>
    );
}
