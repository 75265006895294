import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useHistory} from "react-router-dom";

import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfDatePicker} from '@bnppf-ui-library/mfdatepicker';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';

import {translate} from "helpers/i18n";
import {HeaderProps} from 'components/HeaderProps';
import {useAppDataContext} from "contexts/app-data";
import {ACTIONS} from "helpers/constants";
import {getLanguage} from "helpers/getLanguage";
import {register, Scenario} from "helpers/analytics/analytics";

const Content = styled.div`
    overflow: auto !important;
`;

const todayTemp = new Date()
const todayString = todayTemp.toISOString().split("T")[0]
const today = new Date(todayString)

export const DODForm = () => {
    const history = useHistory();

    const {state, dispatch} = useAppDataContext();
    const [dateOfDeath, setDateOfDeath] = useState(state.deceased.dateOfDeath);
    const [isDateValid, setIsDateValid] = useState(false);
    const dateOfBirthString = state.deceased.birthdate;
    const dateOfBirth = new Date(dateOfBirthString);

    const validation = (value) => {
        setIsDateValid(false);

        if (!value || !value.length) {
            return translate('deceased_date_info.mandatory_date');
        }

        const date = new Date(value)
        const isValidFormat = date instanceof Date && !isNaN(date.getTime())

        if (!isValidFormat || (date > today) || (dateOfBirth > date)) {
            return translate('deceased_date_info.invalid_date');
        }

        setIsDateValid(true);
    }

    const handleDateOfDeathChange = (value) => {
        setDateOfDeath(value)
    }

    const storeAndGoToNext = () => {
        dispatch({
            type: ACTIONS.SET_DECEASED_DATE_OF_DEATH,
            dateOfDeath
        });

        history.push({pathname: "/causeofdeath"});
    }

    useEffect(() => {
        register(Scenario.DECEASED_DATE_OF_DECEASE_PAGE);
    }, []);

    return (
        <div id="DeceasedDate">
            <Content as={MfContent}
                     header={<HeaderProps pathName="/dod"/>}
                     actions={[
                         <MfButton
                             key="next"
                             onClick={storeAndGoToNext}
                             disabled={dateOfDeath === '' || !isDateValid}
                         >
                             {translate('deceased_date_info.next_button')}
                         </MfButton>,
                     ]}
            >
                <MfSpacer size="5"/>
                <MfRow>
                    <MfTypo variant="title3" weight="bold">
                        {translate('deceased_date_info.title')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="4"/>
                <MfRow>
                    <MfDatePicker
                        label={translate('deceased_date_info.date_label')}
                        max={todayString}
                        min={dateOfBirthString}
                        name="deceasedDate"
                        value={dateOfDeath}
                        onChange={handleDateOfDeathChange}
                        validation={validation}
                        errorOnTouched={true}
                        locale={`${getLanguage()}-BE`}
                    />
                </MfRow>
            </Content>
        </div>
    );
}
