import { checkName } from './checkName';
import { checkStreet } from './checkStreet'
import { checkHouseNumberOrBox } from './checkHouseNumberOrBox'
import {translate} from "helpers/i18n";
export const validateName = (value, required = true) => {

    if (required && value === '') {
        return translate('validations.required')
    }

    if (!checkName(value)) {
        return translate('validations.invalid_char_name')
    }

    return '';
}

export const validateStreetName = (value, required = true) => {

    if (required && value === '') {
        return translate('validations.required')
    }

    if (!checkStreet(value)) {
        return translate('validations.invalid_char_street_name')
    }

    return '';
}

export const validateHouseNumberOrBox = (value, required = true) => {

    if (required && value === '') {
        return translate('validations.required')
    }

    if (value && !checkHouseNumberOrBox(value)) {
        return translate('validations.invalid_char_housenumber_box_postal')
    }

    return '';
}

