/**
 * Check if a value is a valid street
 *
 * @param value
 * @returns {boolean}
 */
export const checkStreet = (value = '') => {
  const regEx = /^[0-9a-z \u00c0-\u00ff\-\u0027.]+$/i;

  return regEx.test(value);
}
