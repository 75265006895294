import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { MfSingleSelect, MfTextOption } from '@bnppf-ui-library/mfselect';
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { HeaderProps } from 'components/HeaderProps';
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfTypo } from '@bnppf-ui-library/mftypo';
import { ACTIONS } from "helpers/constants";
import { translate } from "helpers/i18n";
import { useAppDataContext } from 'contexts/app-data'
import {register, Scenario} from "helpers/analytics/analytics";


export const InheritanceContactForm = props => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const [isDeclarantSPOC, setIsDeclarantSPOC] = useState(state.declarant.isDeclarantSPOC)

    const handleInheritanceChange = (value) => {
        setIsDeclarantSPOC(value)
        dispatch({
            type: ACTIONS.SET_DECLARANT_ISDECLARANTSPOC,
            isDeclarantSPOC: value,
        });
        if (value === "NO")
            history.push({ pathname: "/contactName" })
        else if (value === "YES")
            history.push({ pathname: "/phoneoremail" })
        else
            history.push({ pathname: "/deceasedintro" })
    }

    useEffect(() => {
        register(Scenario.DECLARANT_CONTACT_PERSON_PAGE);
    }, []);

    return (
        <MfContent
            {...props}
            header={<HeaderProps pathName="/inheritanceContact" />}
        >
            <MfSpacer size="5" />
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('declarant_info_inheritance.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4" />
            <MfRow style={{ marginBottom: '40px' }}>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={isDeclarantSPOC}
                    onChange={handleInheritanceChange}>
                    <MfTextOption value="YES" label={translate('declarant_info_inheritance.option_text_yes')} />
                    <MfTextOption value="NO" label={translate('declarant_info_inheritance.option_text_no')} />
                    <MfTextOption value="IDONTKNOW"
                        label={translate('declarant_info_inheritance.option_text_dontknow')} />
                </MfSingleSelect>
            </MfRow>
        </MfContent>
    );
};

