import React, {FC, ReactNode} from 'react'
import PropTypes from 'prop-types'
import {initialState, reducers} from "reducers/deathDeclarationReducer";

type AppContext = {
    state: typeof initialState,
    dispatch?: React.Dispatch<unknown>
}
export const AppDataContext: React.Context<AppContext> = React.createContext({state: initialState});

interface AppDataContextProps {
    children: ReactNode
}

export const AppDataContextProvider: FC<AppDataContextProps> = ({children}) => {
    const [state, dispatch] = React.useReducer(reducers, initialState);
    const value = {state, dispatch};
    return (
        <AppDataContext.Provider value={value}>
            {children}
        </AppDataContext.Provider>
    )
};

export const useAppDataContext = (): AppContext => {
    return {
        ...React.useContext(AppDataContext)
    }
}


AppDataContextProvider.propTypes = {
    children: PropTypes.node
};
