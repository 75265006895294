import React from "react";
import { WelcomePage } from "containers/WelcomePage";
import { UploadPage } from "containers/UploadPage";
import { NameForm } from "containers/declarant-info/NameForm";
import { EmailForm } from "containers/declarant-info/EmailForm";
import { RelationshipForm } from "containers/declarant-info/RelationshipForm";
import { InheritanceContactForm } from "containers/declarant-info/InheritanceContactForm";
import { ContactNameForm } from "containers/contact-info/ContactNameForm";
import { ContactEmailForm } from "containers/contact-info/ContactEmailForm";
import { ContactRelationshipForm } from "containers/contact-info/ContactRelationshipForm";
import { PhoneOrEmail } from "containers/contact-mode/PhoneOrEmail";
import { PhoneNumberForm } from "containers/contact-mode/PhoneNumberForm";
import { EmailPostForm } from "containers/contact-mode/EmailPostForm";
import { AddressFrom } from "containers/contact-mode/AddressFrom";

import { DeceasedIntroPage } from "containers/deceased-info/DeceasedIntroPage";
import { DeceasedNameForm } from "containers/deceased-info/DeceasedNameForm";
import { DOBForm } from "containers/deceased-info/DOBForm";
import { DODForm } from "containers/deceased-info/DODForm";
import { CivilStatusFrom } from "containers/deceased-info/CivilStatusFrom";
import { CauseOfDeathForm } from "containers/deceased-info/CauseOfDeathForm";
import { AccountInfoForm } from "containers/deceased-info/AccountInfoForm";

import { SummaryPage } from "containers/SummaryPage";
import { ConfirmationPage } from "containers/ConfirmationPage";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { PageContainer } from "./PageContainer";
import { ErrorPage } from "containers/ErrorPage";

export const routesList = () => {
    return [
        {
            path: '/',
            progress: 0,
            component: WelcomePage
        },
        {
            path: `/upload`,
            progress: 6.25,
            component: UploadPage
        },
        {
            path: `/declarantName`,
            progress: 12.50,
            component: NameForm
        },
        {
            path: `/declarantEmail`,
            progress: 18.75,
            component: EmailForm
        },
        {
            path: `/declarantRelation`,
            progress: 25,
            component: RelationshipForm
        },
        {
            path: `/inheritanceContact`,
            progress: 31.25,
            component: InheritanceContactForm
        },
        {
            path: `/contactName`,
            progress: 32.81,
            component: ContactNameForm
        },
        {
            path: `/contactEmail`,
            progress: 34.37,
            component: ContactEmailForm
        },
        {
            path: `/contactRelation`,
            progress: 35.93,
            component: ContactRelationshipForm
        },
        {
            path: `/phoneoremail`,
            progress: 37.5,
            component: PhoneOrEmail
        },
        {
            path: `/phonenumber`,
            progress: 40.62,
            component: PhoneNumberForm
        },
        {
            path: `/emailpost`,
            progress: 43.75,
            component: EmailPostForm
        },
        {
            path: `/address`,
            progress: 46.87,
            component: AddressFrom
        },
        {
            path: `/deceasedintro`,
            progress: 50,
            component: DeceasedIntroPage
        },
        {
            path: `/deceasedname`,
            progress: 56.25,
            component: DeceasedNameForm
        },
        {
            path: `/dob`,
            progress: 62.50,
            component: DOBForm
        },
        {
            path: `/dod`,
            progress: 68.75,
            component: DODForm
        },
        {
            path: `/causeofdeath`,
            progress: 75,
            component: CauseOfDeathForm
        },
        {
            path: `/accountinfo`,
            progress: 81.25,
            component: AccountInfoForm
        },
        {
            path: `/civilstatus`,
            progress: 87.50,
            component: CivilStatusFrom
        },

        {
            path: `/summary`,
            progress: 93.75,
            component: SummaryPage
        },
        {
            path: `/confirm`,
            component: ConfirmationPage
        },
        {
            path: `/error`,
            component: ErrorPage
        }
    ].reverse()
}

const routes = routesList();

type ReactApplicationProps = {
    history?: RouteComponentProps["history"];
    location?: RouteComponentProps['location'];
};

export class ReactApplication extends React.Component<ReactApplicationProps> {

    constructor(props) {
        super(props);
        this.handlePopStateEvent = this.handlePopStateEvent.bind(this);
    }

    componentDidMount() {
        window.addEventListener('popstate', () => {
            this.handlePopStateEvent();
        });
    }


    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopStateEvent);
    }

    componentDidUpdate(prevProps) {
        if (this.props["location"].pathname !== prevProps.location.pathname) {
            if (this.props.history["entries"][this.props.history["entries"].length - 1].key === this.props["location"].key) {
                window.history.pushState(this.props["location"], null, null);
            }
        }
    }

    handlePopStateEvent() {
        this.props["history"].goBack();
    }


    render() {
        return (
            <PageContainer>
                <Switch>
                    {routes.map((route, i) => (
                        <Route key={i + route.path} path={route.path} component={route.component} />
                    ))}
                </Switch>
            </PageContainer>
        )
    }
}


export const ReactApplicationComponent = withRouter(ReactApplication);
