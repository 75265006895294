enum Scenario {
    WELCOME_PAGE,
    UPLOAD_PAGE,
    DECLARANT_NAME_PAGE,
    DECLARANT_EMAIL_PAGE,
    DECLARANT_RELATION_DECEASED_PAGE,
    DECLARANT_CONTACT_PERSON_PAGE,
    CONTACT_INFO_EMAIL_OR_PHONE_PAGE,
    CONTACT_INFO_PHONE_NUMBER_PAGE,
    CONTACT_INFO_POST_OR_EMAIL_PAGE,
    CONTACT_INFO_OTHER_CONTACT_PERSON_PAGE,
    CONTACT_INFO_EMAIL_PAGE,
    CONTACT_INFO_RELATION_DECEASE_PAGE,
    CONTACT_INFO_POSTAL_ADDRESS_PAGE,
    DECEASED_START_QUESTIONS_PAGE,
    DECEASED_NAME_PAGE,
    DECEASED_BIRTHDATE_PAGE,
    DECEASED_DATE_OF_DECEASE_PAGE,
    DECEASED_CAUSE_OF_DECEASE_PAGE,
    DECEASED_ACCOUNT_NUMBER_PAGE,
    DECEASED_ACCOUNT_NUMBER_I_DONT_KNOW_CLICK,
    DECEASED_MARITAL_STATUS_PAGE,
    SUMMARY_PAGE,
    CONFIRMATION_PAGE,
    ERROR_PAGE
}

interface KeyValuePair {
    [key: string]: string | number | boolean
}

type AnalyticsFunctionType = (parameter?: KeyValuePair) => void

const callAnalytics: AnalyticsFunctionType = (parameters: KeyValuePair) => {
    window.PageBus.publish("analyticsServices", parameters);
}

const welcomePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "intro",
        trackMethod: "pageLoad"
    });
}

const uploadPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "upload death certifcate",
        stepSeq: "1",
        eventAction: "flowStart",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}

const declarantNamePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "declarant:name",
        stepSeq: "2",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}

const declarantEmailPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "declarant:email",
        stepSeq: "2",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const declarantRelationDeceasedPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "declarant:relation deceased",
        stepSeq: "2",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const declarantContactPersonPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "declarant:contact person",
        stepSeq: "2",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoEmailOrPhonePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:email or phone",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoPhoneNumberPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:phone number",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoPostOrEmailPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:post or email",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoOtherContactPersonPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:other contactperson",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoEmailPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:email contactperson",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoRelationDeceasePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:relation contactperson deceased",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const contactInfoPostalAddressPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "contact info:postal address",
        stepSeq: "3",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedStartQuestionsPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:start questions",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedNamePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:name",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedBirthdatePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:birth date",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedDateOfDeceasePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:date of decease",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedCauseOfDeceasePage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:cause of decease",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedAccountNumberPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:account number",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const deceasedAccountNumberPageIDontKnowClick: AnalyticsFunctionType = () => {
    callAnalytics({
        eventType: "button click",
        eventName: "iban not known",
        trackMethod: "trackAction"
    });
}
const deceasedMaritalStatusPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "deceased:marital status",
        stepSeq: "4",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const summaryPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "summary",
        stepSeq: "5",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const confirmationPage: AnalyticsFunctionType = () => {
    callAnalytics({
        pageID: "confirmation",
        stepSeq: "6",
        eventAction: "flowEnd",
        flowType: "self-service",
        flowName: "death declaration",
        trackMethod: "self_Flow"
    });
}
const errorPage: AnalyticsFunctionType = () => {
    callAnalytics({
        errorMessage: "death declaration-something went wrong",
        trackMethod: "errorMessage"
    });
}

const scenarios = {
    [Scenario.WELCOME_PAGE]: welcomePage,
    [Scenario.UPLOAD_PAGE]: uploadPage,
    [Scenario.DECLARANT_NAME_PAGE]: declarantNamePage,
    [Scenario.DECLARANT_EMAIL_PAGE]: declarantEmailPage,
    [Scenario.DECLARANT_RELATION_DECEASED_PAGE]: declarantRelationDeceasedPage,
    [Scenario.DECLARANT_CONTACT_PERSON_PAGE]: declarantContactPersonPage,
    [Scenario.CONTACT_INFO_EMAIL_OR_PHONE_PAGE]: contactInfoEmailOrPhonePage,
    [Scenario.CONTACT_INFO_PHONE_NUMBER_PAGE]: contactInfoPhoneNumberPage,
    [Scenario.CONTACT_INFO_POST_OR_EMAIL_PAGE]: contactInfoPostOrEmailPage,
    [Scenario.CONTACT_INFO_OTHER_CONTACT_PERSON_PAGE]: contactInfoOtherContactPersonPage,
    [Scenario.CONTACT_INFO_EMAIL_PAGE]: contactInfoEmailPage,
    [Scenario.CONTACT_INFO_RELATION_DECEASE_PAGE]: contactInfoRelationDeceasePage,
    [Scenario.CONTACT_INFO_POSTAL_ADDRESS_PAGE]: contactInfoPostalAddressPage,
    [Scenario.DECEASED_START_QUESTIONS_PAGE]: deceasedStartQuestionsPage,
    [Scenario.DECEASED_NAME_PAGE]: deceasedNamePage,
    [Scenario.DECEASED_BIRTHDATE_PAGE]: deceasedBirthdatePage,
    [Scenario.DECEASED_DATE_OF_DECEASE_PAGE]: deceasedDateOfDeceasePage,
    [Scenario.DECEASED_CAUSE_OF_DECEASE_PAGE]: deceasedCauseOfDeceasePage,
    [Scenario.DECEASED_ACCOUNT_NUMBER_PAGE]: deceasedAccountNumberPage,
    [Scenario.DECEASED_ACCOUNT_NUMBER_I_DONT_KNOW_CLICK]: deceasedAccountNumberPageIDontKnowClick,
    [Scenario.DECEASED_MARITAL_STATUS_PAGE]: deceasedMaritalStatusPage,
    [Scenario.SUMMARY_PAGE]: summaryPage,
    [Scenario.CONFIRMATION_PAGE]: confirmationPage,
    [Scenario.ERROR_PAGE]: errorPage
}

const register = (scenario: Scenario, additionalInfo?: KeyValuePair) => {
    scenarios[scenario]?.(additionalInfo);
}

export {register, Scenario};
