import React, {FC, useMemo} from "react";
import PropTypes from "prop-types";
import {MfBackButton, MfHeader} from "@bnppf-ui-library/mfheader";
import {routesList} from "../routes";
import {useHistory} from "react-router-dom";
import {translate} from "helpers/i18n";

const HeaderPropTypes = {
    pathName: PropTypes.string,
};

type HeaderPropsType = PropTypes.InferProps<typeof HeaderPropTypes>;

export const HeaderProps: FC<HeaderPropsType> = ({pathName}) => {
    const history = useHistory();
    const routes = routesList();

    const pathProgress = useMemo(
        () => pathProgressValue(routes, pathName),
        [routes, pathName]
    );

    const handlePopStateEvent = () => {
        history.goBack();
    };

    const calculateProgress = () => {
        return pathProgress;
    };

    const leftIcon = () => {
        return (
            <MfBackButton onClick={handlePopStateEvent}>
                {translate("general.previous")}
            </MfBackButton>
        );
    };
    const actions = {
        left: leftIcon(),
    };

    const progress = calculateProgress();


    if (pathName === "/") {
        return <></>;
    }
    return (
        <MfHeader
            actions={pathName !== "/confirm" ? actions : {}}
            progress={progress}
            title={translate('general.header')}
        />
    );
};

const pathProgressValue = (routes, pathName) => {
    const pathArray = routes.filter((route) => route.path === pathName);
    return pathArray[0].progress;
};


export default HeaderProps;
