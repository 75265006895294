import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {BASE_URL} from '../env';
import {getLanguage} from "helpers/getLanguage";

const countryService = `${BASE_URL}/party/estx-resource-public/v1/countries/`

async function fetchCountry() {
    const countries = await (await fetch(`${countryService}${getLanguage()}`)).json();
    const mappedCountries = [];
    let otherCountry;

    countries.forEach((country) => {
        if (country.iso2 === '') {
            otherCountry = {
                name: country.title,
                number: country.phoneCode,
                code: country.iso2,
                isDefault: country.isDefault,
                priority: country.priority,
                translation: {
                    de: country.title,
                    fr: country.title,
                    nl: country.title
                }
            }
            return;
        }
        mappedCountries.push({
            name: country.title,
            number: country.phoneCode,
            code: country.iso2,
            isDefault: country.isDefault,
            priority: country.priority,
            translation: {
                de: country.title,
                fr: country.title,
                nl: country.title
            }
        })
    })

    mappedCountries.push(otherCountry)
    return ({
        fullCountryList: mappedCountries
    })
}

const initialState = {
    fullCountryList: []
};

export const CountryContext = React.createContext(initialState);

export function CountryContextProvider(props) {
    const [countryList, setCountryList] = useState(initialState)

    useEffect(() => {
        fetchCountry().then(res => setCountryList(res))
    }, [])

    return (
        <CountryContext.Provider value={countryList}>
            {props.children}
        </CountryContext.Provider>
    )
}

CountryContextProvider.propTypes = {
    children: PropTypes.node
};
