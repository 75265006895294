import checkMask from './checkMask';

/**
 * Validate a person's name
 *
 * @param value
 * @returns {boolean}
 */
export const checkName = (value = '') => {
  const regEx = /^[a-z \u00c0-\u00ff\-\u0027]*$/i;

  return checkMask(regEx)(value) && regEx.test(value);
}
