import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { MfButton } from "@bnppf-ui-library/mfbutton";
import { MfMultiSelect, MfTextOption } from '@bnppf-ui-library/mfselect';
import {HeaderProps} from 'components/HeaderProps';
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { ACTIONS } from "helpers/constants";
import { Title } from './Title';
import { translate } from "helpers/i18n";
import {useAppDataContext} from 'contexts/app-data'
import {register, Scenario} from "helpers/analytics/analytics";


export const PhoneOrEmail = props => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const [contactMethod, setContactMethod] = useState(state.declarant.contactMethod)

    const handleContactMethodChange = (value) => {
        setContactMethod(value)
    }

    const movetonext = () => {
        dispatch({
            type: ACTIONS.SET_CONTACT_METHOD,
            contactMethod
        });
        if (contactMethod.indexOf("PHONE") !== -1) {
            history.push({ pathname: "/phonenumber" })
        } else {
            history.push({ pathname: "/emailpost" })
        }
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_EMAIL_OR_PHONE_PAGE);
    }, []);

    return (
        <MfContent {...props} header={<HeaderProps pathName="/phoneoremail" />}
            actions={[
                <MfButton key="next" onClick={movetonext} id="phone-number-next" disabled={contactMethod?.length === 0}>
                    {translate('contact_mode_phone_email.button')}
                </MfButton>,
            ]}>
            <MfSpacer size="5" />
            <MfRow>
                <Title defaultTitle={'contact_mode_phone_email.title'}
                    customTitle={'contact_mode_phone_email.name_title'} />
            </MfRow>
            <MfSpacer size="5" />
            <MfRow style={{ marginBottom: '40px' }}>
                <MfMultiSelect
                    values={contactMethod}
                    //  initialHighlighted={contactMethod}
                    onChange={handleContactMethodChange}>
                    <MfTextOption value="EMAIL" label={translate('contact_mode_phone_email.option_text_email')} />
                    <MfTextOption value="PHONE" label={translate('contact_mode_phone_email.option_text_phone')} />
                </MfMultiSelect>
            </MfRow>
        </MfContent>
    );
}

