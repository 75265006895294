export const ACTIONS = {
    SET_FILE_LIST: "SET_FILE_LIST",
    SET_REQUESTER_FIRSTNAME: "SET_REQUESTER_FIRSTNAME",
    SET_REQUESTER_LASTNAME: "SET_REQUESTER_LASTNAME",
    SET_DECLARANT_FIRST_NAME: "SET_DECLARANT_FIRST_NAME",
    SET_DECLARANT_LAST_NAME: "SET_DECLARANT_LAST_NAME",
    SET_DECLARANT_EMAIL: "SET_DECLARANT_EMAIL",
    SET_DECLARANT_RELATIONSHIP: "SET_DECLARANT_RELATIONSHIP",
    SET_DECLARANT_ISDECLARANTSPOC: "SET_DECLARANT_ISDECLARANTSPOC",
    SET_DECLARANT_POSTAL_ADDRESS: "SET_DECLARANT_POSTAL_ADDRESS",
    SET_CONTACT_FIRST_NAME: "SET_CONTACT_FIRST_NAME",
    SET_CONTACT_LAST_NAME: "SET_CONTACT_LAST_NAME",
    SET_CONTACT_EMAIL: "SET_CONTACT_EMAIL",
    SET_CONTACT_RELATIONSHIP: "SET_CONTACT_RELATIONSHIP",
    SET_DELIVERY_MODE: "SET_DELIVERY_MODE",
    SET_CONTACT_METHOD: "SET_CONTACT_METHOD",
    SET_CONTACT_PHONE_FIXED: "SET_CONTACT_PHONE_FIXED",
    SET_CONTACT_PHONE_GSM: "SET_CONTACT_PHONE_GSM",
    SET_DECEASED_FIRST_NAME: "SET_DECEASED_FIRST_NAME",
    SET_DECEASED_LAST_NAME: "SET_DECEASED_LAST_NAME",
    SET_DECEASED_BIRTHDATE: "SET_DECEASED_BIRTHDATE",
    SET_DECEASED_DATE_OF_DEATH: "SET_DECEASED_DATE_OF_DEATH",
    SET_DECEASED_ACCOUNT_NUMBER: "SET_DECEASED_ACCOUNT_NUMBER",
    SET_CAUSE_DEATH: "SET_CAUSE_DEATH",
    SET_CIVIL_STATUS: "SET_CIVIL_STATUS"
}

export const FILE_UPLOAD_CAPACITY = 6000000;

export const BNP_HOME_URL = {
    FR: "https://www.bnpparibasfortis.be/fr/public/particuliers",
    NL: "https://www.bnpparibasfortis.be/nl/public/particulieren"
};

