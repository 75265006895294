import {BASE_URL} from '../env'

import {FILE_UPLOAD_CAPACITY} from "helpers/constants";
import {translate} from "helpers/i18n";

const uploadService = `${BASE_URL}/party/death-declaration-public/v1/estate-process-documents/`;

const fileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
export const acceptedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];

export const UploadDocument = ({file}, onChange, uniqueID) => {
    const fileInfo = {name: file.name, size: file.size, type: file.type};

    if (file.size > FILE_UPLOAD_CAPACITY) {
        onChange(null, {
            ...fileInfo,
            loading: false,
            error: translate('upload_certificate.upload_error_maxsize')
        });
        return;
    }

    if (file.name.substring(0, file.name.lastIndexOf(".")).length > 40) {
        onChange(null, {
            ...fileInfo,
            loading: false,
            error: translate('upload_certificate.upload_error_filename_maxlength')
        });
        return
    }

    // Check file type here ?
    const fileExt = /\.[a-zA-Z0-9]+$/.exec(file.name);
    if (!(fileTypes.includes(file.type) && fileExt &&
        acceptedExtensions.includes(fileExt[0].toLowerCase()))) {
        onChange(null, {
            ...fileInfo,
            loading: false,
            error: translate('upload_certificate.upload_error_file_format')
        });
        return;
    }

    onChange(null, {...fileInfo, loading: true});

    const formData = new FormData();
    formData.append('documentData', file);
    const fetch_init_options: RequestInit = {
        method: 'POST',
        headers: {
            'X-Correlation-ID': uniqueID
        },
        body: formData
    }
    const fetch_url = `${uploadService}DEATH_CERTIFICATE:upload`;
    fetch(fetch_url, fetch_init_options).catch(() => {
        onChange(null, {
            ...fileInfo,
            loading: false,
            error: translate('upload_certificate.upload_error_internal_error')
        });
    })
        .then((data: Response) => data.text())
        .then(response => {
            if (!response.includes('message')) {
                onChange(response, {...fileInfo, loading: false});
            } else {
                onChange(null, {
                    ...fileInfo,
                    loading: false,
                    error: translate('upload_certificate.upload_error_internal_error')
                });
            }
        })
        .catch(() => {
            onChange(null, {
                ...fileInfo,
                loading: false,
                error: translate('upload_certificate.upload_error_internal_error')
            });
        });
};

