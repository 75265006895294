const categories = [
    {
      isCategory: (value) => /(^0?70|^0?77|^0?78)/.test(value),
      format: "%% %% %% %%",
    },
    {
      isCategory: (value) => /(^0?800|^0?900|^0?902|^0?903|^0?905|^0?909)/.test(value),
      format: "%%% %% %% %%",
    },
    {
      isCategory: (value) => /(^0?45|^0?46|^0?47|^0?48|^0?49)/.test(value),
      format: "%%% %% %% %%",
    },
    {
      isCategory: (value) => /(^0?1|^0?5|^0?6|^0?71|^0?8)/.test(value),
      format: "%% %% %% %%",
    },
    {
      isCategory: (value) => /(^0?2|^0?3|^0?42|^0?43|^0?92|^0?93)/.test(value),
      format: "% %%% %% %%",
    },
  ]
  
  export default function formatFromCategory(value) {
    const index = categories.findIndex((x) => x.isCategory(value))
  
    if (index === -1) {
      return "%%% %%%% %%%%"
    }
  
    let format = categories[index].format
  
    // Make a check for the leading 0 that will be stripped
    // Add an extra % to the start for it since the formats are made for without the leading 0
    if (value[0] === "0") {
      format = `0${format}`
    }
  
    if (index !== -1) {
      return format
    }
  }
  