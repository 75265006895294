import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import { MfTypo } from '@bnppf-ui-library/mftypo';
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfButton } from "@bnppf-ui-library/mfbutton";
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { MfInputField, useInput } from '@bnppf-ui-library/mfinputfield';

import { translate } from "helpers/i18n";
import { ACTIONS } from "helpers/constants";
import { HeaderProps } from 'components/HeaderProps';
import { useAppDataContext } from "contexts/app-data";
import {register, Scenario} from "helpers/analytics/analytics";

export const AccountInfoForm = props => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const accountNumberUpdate = useState(state.deceased.accountNumber);
    const [accountNumber] = accountNumberUpdate;
    const [isIbanValid, setIsIbanValid] = useState(false);
    const { inputProps, ...componentProps } = useInput('ACCOUNTNUMBER', {
        saveRaw: true,
        format: "%% %%%% %%%% %%%%",
        validation: (value) => validateIban(value),
        mask: (value) => !/[^0-9]/.test(value),
        updater: () => accountNumberUpdate,
        errorOnTouched: true
    });

    const validateIban = (value) => {
        const isValid = value.length === 14 || value === '';

        setIsIbanValid(isValid)

        if (!isValid) {
            return translate('account_info_screen.invalid_iban')
        }

        return '';
    }


    const moveToNext = () => {
        if (isIbanValid) {
            dispatch({
                type: ACTIONS.SET_DECEASED_ACCOUNT_NUMBER,
                accountNumber
            });
            history.push({ pathname: "/civilstatus" })
        }
    }

    const handleIDontKnow = () => {
        register(Scenario.DECEASED_ACCOUNT_NUMBER_I_DONT_KNOW_CLICK);

        dispatch({
            type: ACTIONS.SET_DECEASED_ACCOUNT_NUMBER,
            accountNumber: ''
        });
        history.push({ pathname: "/civilstatus" })
    }

    useEffect(() => {
        register(Scenario.DECEASED_ACCOUNT_NUMBER_PAGE);
    }, []);

    return (
        <MfContent
            {...props}
            header={<HeaderProps pathName="/accountinfo" />}
            actions={[
                <MfButton
                    key="unknown"
                    onClick={handleIDontKnow}
                    secondary
                >
                    {translate('account_info_screen.i_dont_know_button')}
                </MfButton>,
                <MfButton
                    key="next"
                    onClick={moveToNext}
                    disabled={accountNumber === '' || !isIbanValid}
                >
                    {translate('account_info_screen.next_button')}
                </MfButton>
            ]}
        >
            <MfSpacer size="5" />
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('account_info_screen.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="2" />
            <MfRow>
                <MfTypo variant="bodytext1">
                    {translate('account_info_screen.subTitle')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4" />
            <MfRow>
                <MfInputField
                    prefix="BE"
                    shrinkLabel
                    label={translate('account_info_screen.iban')}
                    required
                    maxLength={20}
                    {...componentProps}
                    {...inputProps()}
                />
            </MfRow>
        </MfContent>
    );
}
