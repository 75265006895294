import React, {useEffect} from 'react';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfSingleSelect, MfTextOption} from '@bnppf-ui-library/mfselect';

import {HeaderProps} from 'components/HeaderProps';
import {translate} from "helpers/i18n";
import {useHistory} from "react-router-dom";
import {useAppDataContext} from "contexts/app-data";
import {CAUSE_OF_DEATH} from "reducers/deathDeclarationReducer";
import {ACTIONS} from "helpers/constants";
import {register, Scenario} from "helpers/analytics/analytics";

export const CauseOfDeathForm = () => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();

    const handleCauseOfDeathSelection = (value: CAUSE_OF_DEATH) => {
        dispatch({
            type: ACTIONS.SET_CAUSE_DEATH,
            causeOfDeath: value
        });
        history.push({pathname: "/accountinfo"})
    }

    useEffect(() => {
        register(Scenario.DECEASED_CAUSE_OF_DECEASE_PAGE);
    }, []);

    return (
        <MfContent
            header={<HeaderProps pathName="/causeofdeath"/>}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo as="h3" variant="title3" weight="bold">
                    {translate('deceased_cause_screen.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={state.deceased.causeOfDeath}
                    onChange={handleCauseOfDeathSelection}>
                    <MfTextOption
                        value={CAUSE_OF_DEATH.NATURAL}
                        label={translate('deceased_cause_screen.natural_death')}
                        subLabel={translate('deceased_cause_screen.natural_death_helper')}
                    />
                    <MfTextOption
                        value={CAUSE_OF_DEATH.ACCIDENT}
                        label={translate('deceased_cause_screen.accidental_death')}
                    />
                    <MfTextOption
                        value={CAUSE_OF_DEATH.OTHER}
                        label={translate('deceased_cause_screen.other_death')}
                    />
                </MfSingleSelect>
            </MfRow>
            <MfSpacer size="6"/>
        </MfContent>
    );
}

