const personAxes = {
  language: "",
  territory: "",
  audience: "",
  distributionChannel: "",
};

let latestAllCookies = null;
let axesCookie = undefined;

const getCookies = function () {
  if (typeof document !== "undefined") {
    return document.cookie;
  } else {
    return undefined;
  }
};

const getAxesCookie = function () {
  const cookieName = "axes";
  const allCookies = getCookies();

  if (allCookies !== latestAllCookies && allCookies.indexOf(cookieName) >= 0) {
    const allCookiesArray = allCookies.split(";");

    for (const cookie of allCookiesArray) {
      const tempCookie = cookie.trim();
      if (tempCookie.split("=")[0] === cookieName) {
        axesCookie = decodeURIComponent(tempCookie.split("=")[1]);
        break;
      }
    }

    latestAllCookies = allCookies;
  }
  return axesCookie;
};

const cookieValue = function (axeName) {
  const axesCookie = getAxesCookie();

  if (typeof axesCookie !== "undefined" && axesCookie.indexOf("|") >= 0) {
    const axesArray = axesCookie.split("|");
    if (axesArray.length >= 4) {
      personAxes.language = axesArray[0];
      personAxes.territory = axesArray[1];
      personAxes.audience = axesArray[2];
      personAxes.distributionChannel = axesArray[3];
    }
  }
  return personAxes[axeName];
};

const getLanguage = function () {
  return cookieValue("language");
};

const getTerritory = function () {
  return cookieValue("territory");
};

const getAudience = function () {
  return cookieValue("audience");
};

const getDistributionChannel = function () {
  return cookieValue("distributionChannel");
};

const getAxeValue = function (axeIndex) {
  if (axeIndex < 0)
    throw new Error(
      `Invalid axeIndex value received.  Negative indexes are not allowed.`
    );

  const axesCookie = getAxesCookie();

  if (typeof axesCookie !== "undefined" && axesCookie.indexOf("|") >= 0) {
    const axesArray = axesCookie.split("|");
    if (axesArray.length > axeIndex) {
      return axesArray[axeIndex];
    } else {
      throw new Error(
        `Invalid axeIndex value received.  Expected to have at least ${
          axeIndex + 1
        } elements in Axes cookie but only ${
          axesArray.length
        } elements available.`
      );
    }
  }
};

/**
 * Export all public functions
 */
export {
  getAudience,
  getDistributionChannel,
  getLanguage,
  getTerritory,
  getAxeValue,
};
