import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {CountryContext} from 'contexts/country-context'
import {ACTIONS} from "helpers/constants";
import {MfPhoneCustom} from 'components/phone/MfPhoneCustom'
import {Title} from './Title';
import {translate} from "helpers/i18n";
import {useAppDataContext} from "contexts/app-data";
import {register, Scenario} from "helpers/analytics/analytics";


export const PhoneNumberForm = () => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const countryValue = useContext(CountryContext);
    const [fixedPhoneNumber, setFixedPhoneNumber] = useState({
        number: state.contactNumber.fixedPhoneNumber,
        numberAsKeyValue: state.contactNumber.phoneFixed
    })
    const [gsmPhoneNumber, setGsmPhoneNumber] = useState({
        number: state.contactNumber.gsmPhoneNumber,
        numberAsKeyValue: state.contactNumber.phoneGsm
    })

    const handlePhoneFixedChange = (countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue) => {
        setFixedPhoneNumber({
            number: countryCodeWithPhoneNo,
            numberAsKeyValue: {...fixedPhoneNumber.numberAsKeyValue, ...countryCodeWithPhoneNoAsKeyValue}
        })
    }
    const handlePhoneGsmChange = (countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue) => {
        setGsmPhoneNumber({
            number: countryCodeWithPhoneNo,
            numberAsKeyValue: {...gsmPhoneNumber.numberAsKeyValue, ...countryCodeWithPhoneNoAsKeyValue}
        })
    }

    const movetonext = () => {
        dispatch({
            type: ACTIONS.SET_CONTACT_PHONE_FIXED,
            phoneFixed: {
                fixedPhoneNumberAsKeyValue: fixedPhoneNumber.numberAsKeyValue,
                fixedPhoneNumber: fixedPhoneNumber.number
            }
        });
        dispatch({
            type: ACTIONS.SET_CONTACT_PHONE_GSM,
            phoneGsm: {gsmPhoneNumberAsKeyValue: gsmPhoneNumber.numberAsKeyValue, gsmPhoneNumber: gsmPhoneNumber.number}
        });
        history.push({pathname: "/emailpost"})
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_PHONE_NUMBER_PAGE);
    }, []);

    return (
        <MfContent
            header={<HeaderProps pathName="/phonenumber"/>}
            actions={[
                <MfButton key="next" onClick={movetonext} id="phone-number-next">
                    {translate('declarant_info_name.button')}
                </MfButton>,
            ]}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <Title defaultTitle={'contact_mode_phone.title'} customTitle={'contact_mode_phone.name_title'}/>
            </MfRow>
            <MfSpacer size="2"/>
            <MfRow>
                <MfTypo variant="bodytext1">
                    {translate('contact_mode_phone.subtitle')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="5"/>
            <MfRow>
                <MfPhoneCustom
                    value={fixedPhoneNumber.number}
                    onChange={(countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue) => handlePhoneFixedChange(countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue)}
                    countryList={countryValue.fullCountryList}
                    searchLabel={translate('contact_mode_phone.search')}
                    countrySelectionTitle={translate('contact_mode_phone.popup_title')}
                    countryLabel={translate('contact_mode_phone.country')}
                    numberLabel={translate('contact_mode_phone.fixed_phone_placeholder')}
                />
            </MfRow>
            <MfSpacer size="2"/>
            <MfRow>
                <MfPhoneCustom
                    value={gsmPhoneNumber.number}
                    onChange={(countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue) => handlePhoneGsmChange(countryCodeWithPhoneNo, countryCodeWithPhoneNoAsKeyValue)}
                    countryList={countryValue.fullCountryList}
                    searchLabel={translate('contact_mode_phone.search')}
                    countrySelectionTitle={translate('contact_mode_phone.popup_title')}
                    countryLabel={translate('contact_mode_phone.country')}
                    numberLabel={translate('contact_mode_phone.gsm_phone_placeholder')}
                />
            </MfRow>
        </MfContent>
    );
}

