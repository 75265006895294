import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react'
import { createRoot } from 'react-dom/client';
import { MfGlobalProvider } from '@bnppf-ui-library/mfglobal-provider'
import { MfLoader } from '@bnppf-ui-library/mfloader';

import { createMemoryHistory } from "history"
import { i18n } from "helpers/i18n";
// contexts
import { AppDataContextProvider } from 'contexts/app-data'
import { CountryContextProvider } from "contexts/country-context";
import { CityContextProvider } from "contexts/city-context";
//Components
import { ReactApplicationComponent } from './routes'
import { Router } from "react-router";
import initDOD, { getLangFromUrl } from "./Init";
import { PageContainer } from "./PageContainer";


const loaderLabels = {FR: "Chargement en cours", NL: "Aan het laden"}; 
const titleLabels = { FR: "Déclaration de décès", NL: "Aangifte overlijden" };
const lang = getLangFromUrl() || "FR";
document.title = titleLabels[lang];
const elementId = "root";
const memoryHistory = createMemoryHistory();
const container = document.getElementById(elementId);
const root = createRoot(container);
const renderApp = () => root.render(
    <Router history={memoryHistory}>
        <MfGlobalProvider>
            <CountryContextProvider>
                <CityContextProvider>
                    <AppDataContextProvider>
                        <ReactApplicationComponent history={memoryHistory} location={window.location} />
                    </AppDataContextProvider>
                </CityContextProvider>
            </CountryContextProvider>
        </MfGlobalProvider>
    </Router>
);

// Loader
const renderLoader = () => root.render(
    <MfGlobalProvider>
        <PageContainer>
            <MfLoader text={loaderLabels[lang]} overlay={false} />
        </PageContainer>
    </MfGlobalProvider>
);

renderLoader();
initDOD().then(
    () => {
        let translations;
        try {
            translations = JSON.parse(localStorage.getItem("translations"))
        } catch (e) {
            translations = "";
        } finally {
            i18n.init({ translations });
            renderApp();
            window.adobeDataLayer.push({
                event: "pageView",
                page: {
                    pageInfo: {accessibility: "public", pageID: "intro", channel: "52", language: lang.toLowerCase(), brand: "fb"},
                    category: {primaryCategory: "priv", "subCategory1": "death declaration"}
                }
            })
        }
    }
)
