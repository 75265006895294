import React, {useEffect} from 'react';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {translate} from "helpers/i18n";
import ErrorImage from "assets/svg/error.svg";
import styled from 'styled-components';
import {getLanguage} from "helpers/getLanguage";
import {BNP_HOME_URL} from "helpers/constants";
import {register, Scenario} from "helpers/analytics/analytics";


const ConfirmImage = styled.img`
    width: 264px;
    height: 176px;
`;

const CenteredMFTypo = styled.h1`
    text-align: center;
`;

export const ErrorPage = () => {

    const handleCloseButton = () => {
        const language = getLanguage();
        window.location.href = BNP_HOME_URL[language];
    }

    useEffect(() => {
        register(Scenario.ERROR_PAGE);
    }, []);

    return (
        <>
            <MfContent header={<HeaderProps pathName="/error" />}
                actions={[
                    <MfButton key="next" onClick={handleCloseButton}>
                        {translate('error_page.button')}
                    </MfButton>,
                ]}>
                <MfSpacer size="5" />
                <MfRow>
                    <ConfirmImage alt="errorImage" src={ErrorImage} />
                </MfRow>
                <MfSpacer size="5" />
                <MfRow>
                    <CenteredMFTypo as={MfTypo} variant="title3" weight="bold" >
                        {translate('error_page.title')}
                    </CenteredMFTypo>
                </MfRow>
                <MfSpacer size="2" />
                <MfRow>
                    <MfTypo variant="bodytext1">
                        {translate('error_page.subtitle')}
                    </MfTypo>
                </MfRow>
            </MfContent>
        </>
    );
}

