import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { MfInputField } from '@bnppf-ui-library/mfinputfield';
import { HeaderProps } from 'components/HeaderProps';
import { MfButton } from "@bnppf-ui-library/mfbutton";
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfTypo } from '@bnppf-ui-library/mftypo';
import { useAppDataContext } from 'contexts/app-data'
import { ACTIONS } from "helpers/constants";
import { validateEmail } from "helpers/validations/EmailValidations";
import { translate } from "helpers/i18n";
import {register, Scenario} from "helpers/analytics/analytics";

export const ContactEmailForm = props => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const [emailAddress, setEmailAddress] = useState(state.contact.emailAddress)
    const [emailError, setEmailError] = useState('')


    const onChangeHandle = (e) => {
        const value = e.target.value;
        setEmailError(validateEmail(value));
        setEmailAddress(value)
    }

    const moveToNext = () => {
        dispatch({
            type: ACTIONS.SET_CONTACT_EMAIL,
            emailAddress
        });
        history.push({ pathname: "/contactRelation" })
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_EMAIL_PAGE);
    }, []);

    return (
        <MfContent {...props} header={<HeaderProps pathName="/contactEmail" />}
            actions={[
                <MfButton key="next" onClick={moveToNext}
                    disabled={emailError !== '' || emailAddress === ''}>
                    {translate('contact_info_email.button')}
                </MfButton>,
            ]}>
            <MfSpacer size="5" />
            <MfRow>
                <MfTypo variant="title3" weight="bold">
                    {translate('contact_info_email.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="2" />
            <MfRow>
                <MfTypo variant="bodytext1">
                    {translate('contact_info_email.subtitle')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="5" />
            <MfRow>
                <MfInputField
                    name="MAIL"
                    label={translate('contact_info_email.email_text')}
                    required
                    maxLength={140}
                    value={emailAddress}
                    onChange={onChangeHandle}
                    error={emailError} />
            </MfRow>
        </MfContent>
    );
}

