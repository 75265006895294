import checkMask from './checkMask';

/**
 * Check validity of an email address
 *
 * @param value
 * @returns {boolean}
 */
export const checkEmail = (value = '') => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const maskRegEx = /[a-z]|\d|[!#$%&'.@*+\-/=?^_`{|}\u007e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]/i;
  return checkMask(maskRegEx)(value) && (!value || regEx.test(value));
}

