/**
 * Check if a value is a correct house number / Box
 *
 * @param value
 * @returns {boolean}
 */
export const checkHouseNumberOrBox = (value = '') => {
  const regEx = /^[0-9a-z \u0020-]+$/i;

  return regEx.test(value);
}
