import React, { useEffect } from 'react'
import styled from 'styled-components';
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import WelcomeImage from "assets/svg/Welcome.svg";

import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfList} from '@bnppf-ui-library/mflist';
import {useHistory} from "react-router-dom";
import DOMPurify from 'dompurify';
import {translate} from "helpers/i18n";
import {register, Scenario} from 'helpers/analytics/analytics';

const TopBanner = styled.div`
    ${({theme}) => theme.media.desktop`
        display: none;
    `};
`;

const BottomBanner = styled.div`
    display: none;

    ${({theme}) => theme.media.desktop`
        display: block;
        & > img {
            width: 100%;
            border-radius: 8px;
        }
    `};
`;

const Disclaimer = styled.span`
    & > a {
        color: ${({theme}) => theme.color.primary.default} !important;
        font-family: ${({theme}) => theme.font.family.desktop.bold} !important;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const MfListContainer = styled.div`
    li {
        display: flex;
        align-items: flex-start;
    }
`;

const GreyDivider = styled.div`
    width: 69px;
    height: 2px;
    border: solid 1px #dfe1ea;
    background-color: #dfe1ea;
`;

export const WelcomePage = () => {
    const history = useHistory();

    useEffect(() => {
        register(Scenario.WELCOME_PAGE)
    }, []);

    return (
        <div id="WelcomePage">
            <MfContent
                actions={[
                    <MfButton key="next" onClick={() => history.push({pathname: '/upload'})}>
                        {translate('Welcome_Product.next_button')}
                    </MfButton>,
                ]}
            >
                <TopBanner as={MfRow}>
                    <img
                        style={{margin: "0 auto", display: "block"}}
                        src={WelcomeImage}
                        alt="introduction"
                    />
                </TopBanner>
                <MfSpacer size="5"/>
                <MfRow>
                    <MfTypo variant="title3" weight="bold">
                        {translate('Welcome_Product.title')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="3"/>
                <MfRow>
                    <MfTypo variant="bodytext1">
                        {translate('Welcome_Product.subtext_1')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="4"/>
                <MfRow>
                    <MfTypo variant="bodytext1">
                        {translate('Welcome_Product.subtext_2')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="1"/>
                <MfRow>
                    <MfListContainer>
                        <MfList type="check">
                            <li>{translate('Welcome_Product.list_1')}</li>
                            <li>{translate('Welcome_Product.list_2')}</li>
                        </MfList>
                    </MfListContainer>
                </MfRow>
                <MfSpacer size="4"/>
                <MfRow>
                    <GreyDivider></GreyDivider>
                </MfRow>
                <MfSpacer size="3"/>
                <MfRow>
                    <MfTypo variant="bodytext1">
                        <Disclaimer dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(translate('Welcome_Product.text_1'), {
                                ALLOWED_TAGS: ['a'],
                                ALLOWED_ATTR: ['href', 'target']
                            })
                        }}/>
                    </MfTypo>
                </MfRow>
                <MfSpacer size="2"/>
                <MfRow>
                    <MfTypo variant="bodytext1">
                        {translate('Welcome_Product.text_3')}
                    </MfTypo>
                </MfRow>
                <MfSpacer size="4"/>
            </MfContent>
            <BottomBanner>
                <img
                    src={WelcomeImage}
                    alt="introduction"
                />
            </BottomBanner>
        </div>
    )
}
