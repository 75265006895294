import { checkEmail } from './checkEmail';
import {translate} from "helpers/i18n";

export const validateEmail = (value) => {

    if(value === "") {
        return translate('validations.required')
    }

    if (!checkEmail(value)) {
        return translate('validations.email_invalid_char')
    } 

    return '';
}
