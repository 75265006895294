import React, {FC} from 'react';
import styled from "styled-components";

import BannerImage from "assets/svg/banner.svg";
import LogoImage from "assets/png/paribas-fortis.png"
import PropTypes from "prop-types";

const ApplicationWrapper = styled.div`
    display: block;

    ${({theme}) => theme.media.desktop`
        position: relative;
        top: -54px;
        display: table;
        max-width: 76.25em;
        margin: 0 auto;
        border-radius: 8px;
        box-shadow: 1px 2px 14px 0 rgba(0, 0, 0, .08);
        background-color: #fff;
    `};
`;

const BannerWrapper = styled.div`
    display: none;
    height: 108px;
    width: 100%;
    background: #f7faeb;

    ${({theme}) => theme.media.desktop`
        display: block;
    `};
`;

const Banner = styled.div`
    background: url(${BannerImage}) left 0;
    height: 100%;
`;

const LogoWrapper = styled.div`
    display: none;
    padding: 1em;
    background-color: #fff;

    ${({theme}) => theme.media.desktop`
        display: block;
    `};
`;

const Logo = styled.img`
    display: block;
    height: 3.19em;
`;

const PageContainerPropTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
type PageContainerProps = PropTypes.InferProps<typeof PageContainerPropTypes>;

export const PageContainer: FC<PageContainerProps> = ({ children }: PageContainerProps) => {
    return (
        <>
            <LogoWrapper>
                <Logo src={LogoImage} alt="BNP Paribas Fortis"/>
            </LogoWrapper>
            <BannerWrapper>
                <Banner />
            </BannerWrapper>
            <ApplicationWrapper>
                <>
                    {children}
                </>
            </ApplicationWrapper>
        </>
    )
}
