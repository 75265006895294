/**
 * Extra mask regex check to prevent issues in android
 * This matches every character of the value against a given regex
 *
 * @param mask          - Regex against which you want the value to be tested
 * @returns {Function}
 */
function checkMask(mask) {
  return function(value) {
    let valid = true;
    value.split('').forEach(letter => {
      const validChar = mask.test(letter);
      valid = valid && validChar;
    });

    return valid;
  };
}

export default checkMask;
