import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { MfSingleSelect, MfTextOption } from '@bnppf-ui-library/mfselect';
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import { HeaderProps } from 'components/HeaderProps';
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfTypo } from '@bnppf-ui-library/mftypo';
import { ACTIONS } from "helpers/constants";
import { Title } from './Title';
import { translate } from "helpers/i18n";
import { useAppDataContext } from 'contexts/app-data'
import {register, Scenario} from "helpers/analytics/analytics";


export const EmailPostForm = props => {
    const history = useHistory();
    const { state, dispatch } = useAppDataContext();
    const [deliveryMode, setDeliveryMode] = useState(state.declarant.deliveryMode);

    const handleDeliveryModeChange = (value) => {
        setDeliveryMode(value)
        dispatch({
            type: ACTIONS.SET_DELIVERY_MODE,
            deliveryMode: value,
        });
        if (value === "EMAIL")
            history.push({ pathname: "/deceasedintro" })
        else
            history.push({ pathname: "/address" })
    }

    useEffect(() => {
        register(Scenario.CONTACT_INFO_POST_OR_EMAIL_PAGE);
    }, []);

    return (
        <MfContent {...props} header={<HeaderProps pathName="/emailpost" />}>
            <MfSpacer size="5" />
            <MfRow>
                <Title defaultTitle={'contact_mode_post_email.title'}
                    customTitle={'contact_mode_post_email.name_title'} />
            </MfRow>
            <MfSpacer size="2" />
            <MfRow>
                <MfTypo variant="bodytext1">
                    {translate('contact_mode_post_email.subtitle')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4" />
            <MfRow style={{ marginBottom: '40px' }}>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={deliveryMode}
                    onChange={handleDeliveryModeChange}>
                    <MfTextOption value="EMAIL" label={translate('contact_mode_post_email.option_text_email')} />
                    <MfTextOption value="POSTAL" label={translate('contact_mode_post_email.option_text_postal')} />
                </MfSingleSelect>
            </MfRow>
        </MfContent>
    );
}

