import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {CellWrapper, MfCell} from '@bnppf-ui-library/mfcell';
import {MfFontIcon} from '@bnppf-ui-library/mffonticon';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {MfButton} from "@bnppf-ui-library/mfbutton";
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfSeparator} from '@bnppf-ui-library/mfseparator';
import {MfLoader} from '@bnppf-ui-library/mfloader';
import {HeaderProps} from 'components/HeaderProps';
import {translate} from "helpers/i18n";
import {useAppDataContext} from "contexts/app-data";
import {CAUSE_OF_DEATH, CIVIL_STATUS, RELATIONSHIP} from "reducers/deathDeclarationReducer";

import {createPhoneFormatter} from 'components/phone/format';
import {createFormatter} from 'components/accountnumber/formatter';
import {SubmitDeathDeclaration} from 'services/SubmitDeathDeclaration';
import {register, Scenario} from "helpers/analytics/analytics";


const isEmpty = (value: string) => value.length === 0;
const isEmptyPhoneNumber = (value: string) => isEmpty(value.replace("+32", ""));
const getRelationshipLabel = (value) => {
    return {
        [RELATIONSHIP.SPOUSE]: translate('relationship.option_text_spouse'),
        [RELATIONSHIP.LEGALLY_COHABITING]: translate('relationship.option_text_legal_partner'),
        [RELATIONSHIP.CHILD]: translate('relationship.option_text_child'),
        [RELATIONSHIP.PARENT]: translate('relationship.option_text_parent'),
        [RELATIONSHIP.SIBLING]: translate('relationship.option_text_sibling'),
        [RELATIONSHIP.OTHER]: translate('relationship.option_text_other')
    }[value] || translate('relationship.option_text_other');
}

const getCauseLabel = (value) => {
    return {
        [CAUSE_OF_DEATH.NATURAL]: translate('deceased_cause_screen.natural_death'),
        [CAUSE_OF_DEATH.ACCIDENT]: translate('deceased_cause_screen.accidental_death'),
        [CAUSE_OF_DEATH.OTHER]: translate('deceased_cause_screen.other_death')
    }[value] || translate('deceased_cause_screen.other_death');
}

const getCivilStatusLabel = (value) => {
    return {
        [CIVIL_STATUS.MARRIED]: translate('deceased_civil_status_screen.married'),
        [CIVIL_STATUS.DIVORCEE]: translate('deceased_civil_status_screen.divorced'),
        [CIVIL_STATUS.SINGLE]: translate('deceased_civil_status_screen.single'),
        [CIVIL_STATUS.LEGALLY_COHABITING]: translate('deceased_civil_status_screen.legal_partner'),
        [CIVIL_STATUS.WIDOW]: translate('deceased_civil_status_screen.widow'),
        [CIVIL_STATUS.OTHER]: translate('deceased_civil_status_screen.other'),
    }[value] || translate('deceased_civil_status_screen.other');
}

const getContactMode = (value) => {
    if (value.indexOf("PHONE") !== -1 && value.indexOf("EMAIL") !== -1) {
        return `${translate('summary.contact_mode_email')} , ${translate('summary.contact_mode_phoneNo')}`
    } else if (value.indexOf("PHONE") !== -1) {
        return translate('summary.contact_mode_phoneNo')
    }
    return translate('summary.contact_mode_email')
}

const Wrapper = styled.div`
    padding-left: 0.6em;
    width: 100%;
    margin: 0 auto;
    ${({theme}) => theme.media.phablet`
        padding-left: 0;
        width: 356px;
    `};
    ${({theme}) => theme.media.tablet`
        width: 516px !important;
    `};
    ${({theme}) => theme.media.desktop`
        width: 77% !important;
    `};
`

const DeclarantInfo = styled.div`
    font-family: inherit;
`
const ContactPersonInfo = styled.div`
    font-family: inherit;
`
const DeceasedInfo = styled.div`
    font-family: inherit;
`

const UploadDocTitle = styled.h1`
    color: ${({theme}) => theme.color.text.lighter};
    text-transform: uppercase;
    margin-top: 8px;
`

const CustomMfSeparator = styled.div`
    margin: 8px 0;
`

export const MfSeparatorComponent = () => {
    return <CustomMfSeparator><MfSeparator direction="horizontal"/></CustomMfSeparator>
}

export const SummaryPage = () => {
    const {state} = useAppDataContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);


    const editNamePage = () => {
        if (state.declarant.isDeclarantSPOC === 'YES')
            history.push({pathname: '/declarantName'})
        else
            history.push({pathname: '/contactName'})
    }

    const editEmailPage = () => {
        if (state.declarant.isDeclarantSPOC === 'YES')
            history.push({pathname: '/declarantEmail'})
        else
            history.push({pathname: '/contactEmail'})
    }

    const editRelationPage = () => {
        if (state.declarant.isDeclarantSPOC === 'YES')
            history.push({pathname: '/declarantRelation'})
        else
            history.push({pathname: '/contactRelation'})
    }

    useEffect(() => {
        register(Scenario.SUMMARY_PAGE);
    }, []);

    const displayPostalAddress = () => {
        return (
            <p className='label'>
                <MfTypo variant="bodytext1"
                        weight="bold">
                    {`${state.declarant.postalAddress.street} ${state.declarant.postalAddress.number}`}{state.declarant.postalAddress.box !== '' && `, ${translate('summary.exchange_address_box')} ${state.declarant.postalAddress.box}`}
                </MfTypo>
                <MfTypo variant="bodytext1"
                        weight="bold">
                    {`${state.declarant.postalAddress.postalCode} ${state.declarant.postalAddress.municipality}`}
                </MfTypo>
                {state.declarant.postalAddress.country.code !== 'BE' && <MfTypo variant="bodytext1"
                                                                                weight="bold">
                    {state.declarant.postalAddress.country.name}
                </MfTypo>}
            </p>
        );
    }

    const displayPhoneNumber = () => {
        return (<p className='label'>
            {!isEmptyPhoneNumber(state.contactNumber.fixedPhoneNumber) &&
                <MfTypo variant="bodytext1" weight="bold">
                    {`${state.contactNumber.phoneFixed.prefix} ${createPhoneFormatter(state.contactNumber.phoneFixed.prefix.replace("+", "")).format(state.contactNumber.phoneFixed.value)}`}
                </MfTypo>}
            {!isEmptyPhoneNumber(state.contactNumber.gsmPhoneNumber) &&
                <MfTypo variant="bodytext1" weight="bold">
                    {`${state.contactNumber.phoneGsm.prefix} ${createPhoneFormatter(state.contactNumber.phoneGsm.prefix.replace("+", "")).format(state.contactNumber.phoneGsm.value)}`}
                </MfTypo>}
        </p>)
    }

    const submitDeclaration = useCallback(() => {
        setIsLoading(true)
        SubmitDeathDeclaration(state)
            .then(() => {
                history.push({pathname: "/confirm"})
            })
            .catch(() => {
                history.push({pathname: "/error"})
            })
            .finally(() => {
                setIsLoading(false)
            });
    }, [state]);

    return (
        <>
            {isLoading && <MfLoader text={translate('loader.title')}/>}
            <MfContent
                header={<HeaderProps pathName="/summary"/>}
                actions={[
                    <MfButton
                        key="next"
                        onClick={submitDeclaration}
                        id="summary-confirm"
                    >
                        {translate('summary.confirm')}
                    </MfButton>,
                ]}
            >
                <MfSpacer size="5"/>
                <MfRow>
                    <MfTypo as="h4" variant="title4" weight="bold">
                        {translate('summary.title')}
                    </MfTypo>
                </MfRow>
                <DeclarantInfo>
                    <MfSpacer size="4"/>
                    <MfRow>
                        <MfTypo as="h4" variant="title4" weight="semibold">
                            {translate('summary.declarant_info')}
                        </MfTypo>
                    </MfRow>
                    <MfSpacer size="4"/>
                    <Wrapper>
                        <CellWrapper onClick={() => history.push({pathname: '/declarantName'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.declarant_name")}
                                value={`${state.declarant.firstname} ${state.declarant.lastname}`}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/declarantEmail'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.declarant_email")}
                                value={state.declarant.emailAddress}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/declarantRelation'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.declarant_relation_with_deceased")}
                                value={getRelationshipLabel(state.declarant.relationshipToDecease)}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                    </Wrapper>
                </DeclarantInfo>
                {state.declarant.isDeclarantSPOC !== "IDONTKNOW" && (
                    <ContactPersonInfo>
                        <MfSpacer size="5"/>
                        <MfRow>
                            <MfTypo as="h4" variant="title4" weight="semibold">
                                {translate('summary.contact_person_info')}
                            </MfTypo>
                        </MfRow>
                        <MfSpacer size="4"/>
                        <Wrapper>
                            <CellWrapper onClick={editNamePage}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.contact_person_name")}
                                    value={state.declarant.isDeclarantSPOC === 'NO' ? `${state.contact.firstname} ${state.contact.lastname}` :
                                        `${state.declarant.firstname} ${state.declarant.lastname}`}
                                />
                            </CellWrapper>
                            <MfSeparatorComponent/>
                            <CellWrapper
                                onClick={editEmailPage}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.contact_person_email")}
                                    value={state.declarant.isDeclarantSPOC === 'NO' ? state.contact.emailAddress : state.declarant.emailAddress}
                                />
                            </CellWrapper>
                            <MfSeparatorComponent/>
                            <CellWrapper onClick={editRelationPage}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.contact_person_relation_with_deceased")}
                                    value={state.declarant.isDeclarantSPOC === 'NO' ? getRelationshipLabel(state.contact.relationshipToDecease) :
                                        getRelationshipLabel(state.declarant.relationshipToDecease)
                                    }
                                />
                            </CellWrapper>
                            <MfSeparatorComponent/>
                            <CellWrapper onClick={() => history.push({pathname: '/phoneoremail'})}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.contact_mode")}
                                    value={getContactMode(state.declarant.contactMethod)}
                                />
                            </CellWrapper>
                            <MfSeparatorComponent/>
                            {(state.declarant.contactMethod.indexOf('PHONE') !== -1 &&
                                (!isEmptyPhoneNumber(state.contactNumber.fixedPhoneNumber) || !isEmptyPhoneNumber(state.contactNumber.gsmPhoneNumber))) && (
                                <>
                                    <CellWrapper
                                        onClick={() => history.push({pathname: '/phonenumber'})}>
                                        <MfCell
                                            icon={<MfFontIcon color="primary:default" icon="fonticons-edit"
                                                              size="24px"/>}
                                            iconPosition="right"
                                            label={translate("summary.contact_person_contact_info")}
                                            value={displayPhoneNumber()}
                                        />
                                    </CellWrapper>
                                    <MfSeparatorComponent/>
                                </>
                            )}
                            <CellWrapper onClick={() => history.push({pathname: '/emailpost'})}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.document_exchange_method")}
                                    value={(state.declarant.deliveryMode !== "EMAIL") ?
                                        translate("summary.document_exchange_by_courier") : translate("summary.document_exchange_by_email")}
                                />
                            </CellWrapper>
                            <MfSeparatorComponent/>
                            {(state.declarant.deliveryMode === 'POSTAL') && (
                                <>
                                    <CellWrapper
                                        onClick={() => history.push({pathname: '/address'})}>
                                        <MfCell
                                            icon={<MfFontIcon color="primary:default" icon="fonticons-edit"
                                                              size="24px"/>}
                                            iconPosition="right"
                                            label={translate("summary.document_exchange_address")}
                                            value={displayPostalAddress()}
                                        />
                                    </CellWrapper>
                                    <MfSeparatorComponent/>
                                </>
                            )}
                        </Wrapper>
                    </ContactPersonInfo>
                )}
                <DeceasedInfo>
                    <MfSpacer size="4"/>
                    <MfRow>
                        <MfTypo as="h4" variant="title4" weight="semibold">
                            {translate('summary.deceased_person')}
                        </MfTypo>
                    </MfRow>
                    <MfSpacer size="4"/>
                    <Wrapper>
                        <CellWrapper onClick={() => history.push({pathname: '/deceasedname'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.deceased_name")}
                                value={`${state.deceased.firstname} ${state.deceased.lastname}`}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/dob'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.deceased_dob")}
                                value={state.deceased.birthdate.split('-').reverse().join('/')}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/dod'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.deceased_date")}
                                value={state.deceased.dateOfDeath.split('-').reverse().join('/')}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/causeofdeath'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.deceased_cause")}
                                value={getCauseLabel(state.deceased.causeOfDeath)}
                            />
                        </CellWrapper>
                        {state.deceased.accountNumber && (<> <MfSeparatorComponent/>
                            <CellWrapper onClick={() => history.push({pathname: '/accountinfo'})}>
                                <MfCell
                                    icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                    iconPosition="right"
                                    label={translate("summary.deceased_account_number")}
                                    value={'BE' + createFormatter("%% %%%% %%%% %%%%").format(state.deceased.accountNumber)}
                                />
                            </CellWrapper></>)}
                        <MfSeparatorComponent/>
                        <CellWrapper onClick={() => history.push({pathname: '/civilstatus'})}>
                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={translate("summary.deceased_civil_status")}
                                value={getCivilStatusLabel(state.deceased.civilStatus)}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>

                        <CellWrapper>
                            <UploadDocTitle as={MfTypo} variant="bodytext1"
                                            weight="bold">{translate("summary.deceased_certificate")}</UploadDocTitle>
                        </CellWrapper>
                        <MfSeparator direction="horizontal"/>
                        <CellWrapper onClick={() => history.push({pathname: '/upload'})}>

                            <MfCell
                                icon={<MfFontIcon color="primary:default" icon="fonticons-edit" size="24px"/>}
                                iconPosition="right"
                                label={''}
                                value={`${state.filesList.length} ${translate("summary.deceased_documents_added")}`}
                            />
                        </CellWrapper>
                        <MfSeparatorComponent/>
                    </Wrapper>
                </DeceasedInfo>
            </MfContent>
        </>
    );
}


