import React, {FC} from 'react';
import {MfTypo} from '@bnppf-ui-library/mftypo';
import { useAppDataContext} from 'contexts/app-data'
import PropTypes, {string} from "prop-types";
import {translate} from "helpers/i18n";

const TitlePropTypes = {
    customTitle: string,
    defaultTitle: string
}
type TitleProps = PropTypes.InferProps<typeof TitlePropTypes>;


export const Title: FC<TitleProps> = ({customTitle, defaultTitle}: TitleProps) => {
    const {state} = useAppDataContext();
    const getTitle = () => {
        let headerTitle = translate(defaultTitle)
        const isDeclarantSPOC = state.declarant.isDeclarantSPOC;
        if (isDeclarantSPOC === "NO") {
            headerTitle = translate(customTitle)
            headerTitle = headerTitle.replace("###var_firstname###", state.contact.firstname);
            headerTitle = headerTitle.replace("###var_lastname###", state.contact.lastname);
        }
        return headerTitle;
    }

    return (
        <MfTypo variant="title3" weight="bold">
            {getTitle()}
        </MfTypo>
    )
}

