import formatBelgianNumbers from "./be"
import createFormatter from "./stringFormatter"

const getPhoneFormat = (number, countryCode) => {
    if (countryCode === "32") {
        return formatBelgianNumbers(number)
    }

    return "%%% %%% %%%%"
};

export const createPhoneFormatter = countryCode => ({
  format(text = "", {eager = false} = {}) {
    const formatText = getPhoneFormat(text, countryCode)
    const formatter = createFormatter(formatText, {limitLength: false})

    return formatter.format(text, {eager})
  },
  unformat(text = "") {
    return text.replace(/\s/g, "")
  },
});
