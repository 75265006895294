export const getLanguage = () => {
    const language = localStorage.getItem("language");
    if (language === "FR") {
        return "FR";
    }
    if (language === "NL") {
        return "NL";
    }
    return "FR";
}
