import { getAudience } from "../axes/axes";
const SUPPORTED_LANGUAGES = ["FR", "NL"];
const langFromUrl = document.location.pathname.split("/")[1].toUpperCase();

export const i18n = (() => {
  let brands = initBrands();
  let language = SUPPORTED_LANGUAGES.includes(langFromUrl)
    ? langFromUrl.toLowerCase()
    : (localStorage.getItem("language") || "fr").toLowerCase();
  let brandCode = getAudience() || "fb";
  let brand = brands[brandCode];
  let labels = {};

  const init = ({ translations }, forcedLanguage?:string) => {
    labels = translations;
    brands = initBrands();
    brandCode = getAudience() || "fb";
    brand = brands[brandCode];

    if (
      typeof forcedLanguage !== "undefined" &&
      typeof forcedLanguage === "string" &&
      SUPPORTED_LANGUAGES.includes(forcedLanguage.toUpperCase())
    ) {
      language = forcedLanguage.toLowerCase();
    } else {
      language = SUPPORTED_LANGUAGES.includes(langFromUrl)
        ? langFromUrl.toLowerCase()
        : "fr";
    }
  };

  const translate = (label) => {
    let translation = getTranslation(label, labels[brand][language]);

    translation = translation
      ? translation
      : getTranslation(label, labels["default"][language]);

    if (!translation) {
      translation = label;
    }

    return translation;
  };
  return {
    translate,
    init,
  };
})();

function getTranslation(key, translations) {
  if (key.indexOf(".") > -1) {
    const keys = key.split(".");
    let value = translations;
    keys.forEach((akey) => {
      value = value ? value[akey] : "";
    });
    return value;
  } else {
    if (typeof translations[key] !== "string")
      throw new Error(
        `expected translation for the label: ${key} should be a string but an object was returned instead!`
      );
    return translations[key];
  }
}

function initBrands() {
  return {
    fb: "default",
    kn: "fintro",
    hb: "hello_bank",
  };
}

const translate = i18n.translate;
const init = i18n.init;

export { translate, init };
